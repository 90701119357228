import { toast } from "helpers/Utils";
import {
  ADD_HOSPITALS_LIST,
  ADD_HOSPITALS_LIST_FAILURE,
  ADD_HOSPITALS_LIST_SUCCESS,
  GET_HOSPITALS_LIST,
  GET_HOSPITALS_LIST_FAILURE,
  GET_HOSPITALS_LIST_SUCCESS,
  UPDATE_HOSPITALS_LIST,
  UPDATE_HOSPITALS_LIST_FAILURE,
  UPDATE_HOSPITALS_LIST_SUCCESS,
} from "./Action";
const initialState = {
  hospitalsList: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOSPITALS_LIST:
      return { ...state, loading: true };
    case GET_HOSPITALS_LIST_SUCCESS:
      return { ...state, hospitalsList: action.data, loading: false };
    case GET_HOSPITALS_LIST_FAILURE:
      toast("Failed fetch list !", "", "error");
      return { ...state, hospitalsList: [], loading: false };
    case ADD_HOSPITALS_LIST:
      return { ...state, loading: true };
    case ADD_HOSPITALS_LIST_SUCCESS:
      toast("Hospital Added successfully !", "", "success");
      return { ...state, loading: false };
    case ADD_HOSPITALS_LIST_FAILURE:
      toast("Failed to add !", "", "error");
      return { ...state, loading: false };

    case UPDATE_HOSPITALS_LIST:
      return { ...state, loading: true };

    case UPDATE_HOSPITALS_LIST_SUCCESS:
      toast("Hospital Updated successfully !", "", "success");
      return { ...state, loading: false };

    case UPDATE_HOSPITALS_LIST_FAILURE:
      toast("Failed to update !", "", "error");
      return { ...state, loading: false };

    default:
      return state;
  }
}
