import { toast } from "helpers/Utils";
import {
  GET_PARTNER_LEAD_DETAIL,
  GET_PARTNER_LEAD_DETAIL_FAILED,
  GET_PARTNER_LEAD_DETAIL_SUCCESS,
  PARTNER_ASSIGN_EXPRT_LIST,
  PARTNER_ASSIGN_EXPRT_LIST_FAILED,
  PARTNER_ASSIGN_EXPRT_LIST_SUCCESS,
  PARTNER_ASSIGN_LEAD,
  PARTNER_ASSIGN_LEAD_FAILED,
  PARTNER_ASSIGN_LEAD_SUCCESS,
  PARTNER_GET_CALL_LIST,
  PARTNER_GET_CALL_LIST_FAILED,
  PARTNER_GET_CALL_LIST_SUCCESS,
  PARTNER_LEAD_ADD_COMMENT,
  PARTNER_LEAD_ADD_COMMENT_FAILED,
  PARTNER_LEAD_ADD_COMMENT_SUCCESS,
  PARTNER_LEAD_ASSIGN_EXPERT,
  PARTNER_LEAD_ASSIGN_EXPERT_FAILED,
  PARTNER_LEAD_ASSIGN_EXPERT_SUCCESS,
  PARTNER_LEAD_ASSIGN_USER_LIST,
  PARTNER_LEAD_ASSIGN_USER_LIST_FAILED,
  PARTNER_LEAD_ASSIGN_USER_LIST_SUCCESS,
  PARTNER_LEAD_BY_SEARCH,
  PARTNER_LEAD_BY_SEARCH_FAILED,
  PARTNER_LEAD_BY_SEARCH_SUCCESS,
  PARTNER_LEAD_BY_STATUS,
  PARTNER_LEAD_BY_STATUS_FAILED,
  PARTNER_LEAD_BY_STATUS_SUCCESS,
  PARTNER_LEAD_GET_COMMENT,
  PARTNER_LEAD_GET_COMMENT_FAILED,
  PARTNER_LEAD_GET_COMMENT_SUCCESS,
  PARTNER_LEAD_GET_DOCUMENT,
  PARTNER_LEAD_GET_DOCUMENT_FAILED,
  PARTNER_LEAD_GET_DOCUMENT_SUCCESS,
  PARTNER_LEAD_GROUPS,
  PARTNER_LEAD_GROUPS_FAILED,
  PARTNER_LEAD_GROUPS_SUCCESS,
  PARTNER_LEAD_UPDATE,
  PARTNER_LEAD_UPDATE_FAILED,
  PARTNER_LEAD_UPDATE_SUCCESS,
} from "./Action";

const initialValue = {
  loading: false,
  leadData: [],
  leadDataCount: "",
  assignUsers: [],
  experts: [],
  callList: [],
  allGroup: [],
  leadComments: "",
  leadDoc: [],
  details: {},
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case PARTNER_LEAD_BY_STATUS:
      return { ...state, loading: true };

    case PARTNER_LEAD_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        leadData: action.data,
        leadDataCount: action?.dataCount,
      };

    case PARTNER_LEAD_BY_STATUS_FAILED:
      return { ...state, loading: false };

    case PARTNER_LEAD_ASSIGN_USER_LIST:
      return { ...state };

    case PARTNER_LEAD_ASSIGN_USER_LIST_SUCCESS:
      return { ...state, assignUsers: action.data };

    case PARTNER_LEAD_ASSIGN_USER_LIST_FAILED:
      return { ...state };

    case PARTNER_ASSIGN_LEAD:
      return { ...state };

    case PARTNER_ASSIGN_LEAD_SUCCESS:
      toast(action.message, "Success", "success");
      return { ...state };

    case PARTNER_ASSIGN_LEAD_FAILED:
      return { ...state };

    case PARTNER_ASSIGN_EXPRT_LIST:
      return { ...state };

    case PARTNER_ASSIGN_EXPRT_LIST_SUCCESS:
      return { ...state, experts: action.data };

    case PARTNER_ASSIGN_EXPRT_LIST_FAILED:
      return { ...state };

    case PARTNER_LEAD_ASSIGN_EXPERT:
      return { ...state };

    case PARTNER_LEAD_ASSIGN_EXPERT_SUCCESS:
      toast(action.message, "Success", "success");
      return { ...state };

    case PARTNER_LEAD_ASSIGN_EXPERT_FAILED:
      return { ...state };

    case PARTNER_GET_CALL_LIST:
      return { ...state };

    case PARTNER_GET_CALL_LIST_SUCCESS:
      return { ...state, callList: action.data };

    case PARTNER_GET_CALL_LIST_FAILED:
      return { ...state };

    case PARTNER_LEAD_UPDATE:
      return { ...state };

    case PARTNER_LEAD_UPDATE_SUCCESS:
      toast(action.message, "Success", "success");
      return { ...state };

    case PARTNER_LEAD_UPDATE_FAILED:
      return { ...state };

    case PARTNER_LEAD_GROUPS:
      return { ...state };

    case PARTNER_LEAD_GROUPS_SUCCESS:
      return { ...state, allGroup: action.data };

    case PARTNER_LEAD_GROUPS_FAILED:
      return { ...state };

    case PARTNER_LEAD_BY_SEARCH:
      return { ...state, loading: true };

    case PARTNER_LEAD_BY_SEARCH_SUCCESS:
      return {
        ...state,
        leadData: action.data,
        leadDataCount: action?.dataCount,
        loading: false,
      };

    case PARTNER_LEAD_BY_SEARCH_FAILED:
      return { ...state, loading: false };

    case PARTNER_LEAD_GET_COMMENT:
      return { ...state };

    case PARTNER_LEAD_GET_COMMENT_SUCCESS:
      return { ...state, leadComments: action.data };

    case PARTNER_LEAD_GET_COMMENT_FAILED:
      return { ...state };

    case PARTNER_LEAD_ADD_COMMENT:
      return { ...state };

    case PARTNER_LEAD_ADD_COMMENT_SUCCESS:
      toast(action.message, "Success", "success");
      return { ...state };

    case PARTNER_LEAD_ADD_COMMENT_FAILED:
      return { ...state };

    case PARTNER_LEAD_GET_DOCUMENT:
      return { ...state };

    case PARTNER_LEAD_GET_DOCUMENT_SUCCESS:
      return { ...state, leadDoc: action.data };

    case PARTNER_LEAD_GET_DOCUMENT_FAILED:
      return { ...state };

    case GET_PARTNER_LEAD_DETAIL:
      return { ...state };

    case GET_PARTNER_LEAD_DETAIL_SUCCESS:
      return { ...state, details: action.data };

    case GET_PARTNER_LEAD_DETAIL_FAILED:
      return { ...state };

    default:
      return { ...state };
  }
};
