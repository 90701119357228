import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from "constants/defaultValues";
import { NotificationManager } from "components/common/react-notifications";
// import moment from "moment";

export const KNOWLARITY_CONFIGS = {
  ["x-api-key"]: "I0SOwyNRvk8o5Qm6gx9RI7MvzHTnxnX29HAqgnAG",
  Authorization: "5380801a-b93f-4047-a2bf-aa3243ffd378",
  channel: "Basic",
  baseUrl: "https://kpi.knowlarity.com",
  superReceptionistNumber: "+919513631312",
};

export const SERVETEL_CONFIGS = {
  Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjM1MTYyNCwiaXNzIjoiaHR0cHM6XC9cL2Nsb3VkcGhvbmUudGF0YXRlbGVzZXJ2aWNlcy5jb21cL3Rva2VuXC9nZW5lcmF0ZSIsImlhdCI6MTY5MDg3NTkxMywiZXhwIjoxOTkwODc1OTEzLCJuYmYiOjE2OTA4NzU5MTMsImp0aSI6IkhrNVdPOFZrSG5Fa0RVNk4ifQ.Ond96_GMwVyj2vZY5EnLOmt-ib3W5wxtT49SJYEhKY8",
  baseUrl: "https://api-smartflo.tatateleservices.com/",
  superReceptionistNumber: "+919513631312",
};

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};
export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  try {
    localStorage.setItem("direction", direction);
  } catch (error) {
    log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = "rounded";
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    log(
      ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
      error
    );
    currentRadius = "rounded";
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    log(
      ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem("currentLanguage") &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem("currentLanguage")
        ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale;
  } catch (error) {
    log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem("currentLanguage", locale);
  } catch (error) {
    log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("insa_accessor") != null
        ? JSON.parse(localStorage.getItem("insa_accessor"))
        : null;
    const insa_agentToken =
      localStorage.getItem("insa_agentToken") != null
        ? JSON.parse(localStorage.getItem("insa_agentToken"))
        : null;
    user = { ...user, insa_agentToken };
  } catch (error) {
    log(">>>>: src/helpers/Utils.js  : insa_accessor -> error", error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("insa_accessor", JSON.stringify(user));
    } else {
      localStorage.removeItem("insa_accessor");
      localStorage.removeItem("insa_agentToken");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("activeSideBar");
      localStorage.removeItem("searchComplaint");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("callingAgentNumber");
    }
  } catch (error) {
    log(">>>>: src/helpers/Utils.js : insa_accessor -> error", error);
  }
};

export const getCurrentDocUploadUser = () => {
  let user  = null;
  try {
    user = localStorage.getItem("doc_upload_accessor") != null ? JSON.parse(localStorage.getItem("doc_upload_accessor")) : null;
    return user
  } catch (error) {
    console.log(error);
  }
}

export const getLocalStorageUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem("insa_accessor"))
    return user
  } catch (error) {
    log(error)
  }
}

// export const getAgentToken = () => {
//   try {
//     user = localStorage.getItem('insa_accessor') != null
//       ? JSON.parse(localStorage.getItem('insa_accessor'))
//       : null;
//   }
// }

export const toast = (msg, subMsg, type) => {
  return NotificationManager[type](subMsg, msg, 5000, null, null, "filled");
};

export const sanitizeFileName = (fileName) => {
  let fileNameArray = fileName.split('.');
  return fileNameArray[0].replace(/[/\\?%*:|"<>-_ ]+/g, '').trim() || "file";
}

// filtration form validations
export const mobileValidation = /^[6789]\d{9}$/;
export const emailValidation = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
export const pinValidation = /^\d{6}$/;
export const nameValidation = /^[a-zA-Z ]+$/;
export const numberValidation = /^\d+$/;
export const panValidation = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const futureDateValidation = (date) => {
  if (date) {
    let enteredDate = new Date(date).getTime();
    let currentDate = new Date().getTime();
    if (enteredDate > currentDate) {
      return true;
    } else {
      return false;
    }
  }
  else {
    return true;
  }
}

export const DateFormat = (date) => {
  let format = new Date(date);
  let dateVal = `${format.getDate()}`;
  let monthVal = `${format.getMonth() + 1}`;
  let yearVal = format.getFullYear();
  if (Number(monthVal) < 10) {
    monthVal = `0${monthVal}`;
  }
  if (Number(dateVal) < 10) {
    dateVal = `0${dateVal}`;
  }

  let combined = `${yearVal}-${monthVal}-${dateVal}`;
  return combined;
}

export const log = (...args) => {
  if (process.env.REACT_APP_ENV === "local") {
    console.log(...args);
  }
}

export const mobileInputValidation = (e) => {
  if (["ArrowUp", "ArrowDown", "-", "+", "e","-", "."].includes(e.key)) {
    e.preventDefault()
    return;
  }
  return e.target.value?.length >= 10 ? ["1","2","3","4","5","6","7","8","9","0"].includes("" + e.key) ? e.preventDefault() : null : ""
}

export const numberFieldValidation = (e) => {
  if (["ArrowUp", "ArrowDown", "-", "+", "e","-"].includes(e.key)) {
    e.preventDefault()
    return;
  }
}

export const IOSToLocal = (date) => {
  let hours = (new Date(date)).getHours() + 5;
  let minutes = (new Date(date)).getMinutes() + 30;
  let fTempDate = new Date(date).setHours(hours)
  let temp = new Date(new Date(fTempDate).setMinutes(minutes)).toISOString()?.slice(0,16)?.replace("T", " : ")
  return temp;
}

export const getStartDateAndEndDate = (startDate, endDate) => {
  try {
    const startDateObject = new Date(startDate);
    startDateObject.setUTCHours(0,0,0,0)
    const startDateTimestampIST = (startDateObject.getTime()) / 1000;
    const endDateObject = new Date(endDate);
    endDateObject.setUTCHours(23,59,59,0)
    const endDateTimestampIST = (endDateObject.getTime()) / 1000;
    return {
      startDateTimestampIST,endDateTimestampIST
    }
  } catch (error) {
      console.log(error)
  }
}

export const localLog = (...args) => {
  if (process.env.REACT_APP_ENV !== "prod") {
    console.log(...args);
  }
}


export const amtInWord = (num) => {

  if (!num) return "Zero Rupees";
  if (num > 1000000000) return "Overflow";

  const single = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const double = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const formatTenth = (digit, prev) => {
     return 0 == digit ? "" : " " + (1 == digit ? double[prev] : tens[digit])
  };
  const formatOther = (digit, next, denom) => {
     return (0 != digit && 1 != next ? " " + single[digit] : "") + (0 != next || digit > 0 ? " " + denom : "")
  };


  let res = "";
  let index = 0;
  let digit = 0;
  let next = 0;
  let words = [];
  if (num += "", isNaN(parseInt(num))) {
     res = "";
  }
  else if (parseInt(num) > 0 && num.length <= 10) {
     for (index = num.length - 1; index >= 0; index--) switch (digit = num[index] - 0, next = index > 0 ? num[index - 1] - 0 : 0, num.length - index - 1) {
        case 0:
           words.push(formatOther(digit, next, ""));
        break;
        case 1:
           words.push(formatTenth(digit, num[index + 1]));
           break;
        case 2:
           words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] && 0 != num[index + 2] ? " and" : "") : "");
           break;
        case 3:
           words.push(formatOther(digit, next, "Thousand"));
           break;
        case 4:
           words.push(formatTenth(digit, num[index + 1]));
           break;
        case 5:
           words.push(formatOther(digit, next, "Lakh"));
           break;
        case 6:
           words.push(formatTenth(digit, num[index + 1]));
           break;
        case 7:
           words.push(formatOther(digit, next, "Crore"));
           break;
        case 8:
           words.push(formatTenth(digit, num[index + 1]));
           break;
        case 9:
           words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] || 0 != num[index + 2] ? " and" : " Crore") : "")
     }
     res = words.reverse().join("")
  } else res = "";
  return res
};


export const GIType = ['Travel', 'Fidelity', 'Marine', 'Property', 'Fire', 'Home Insurance', 'Personal Accident', 'Critical Illness', 'PMSBY Claim', "Loan Protection Policy", "Death Claim", "Others"];