import { createContext, useState, useEffect } from 'react';

export const HCRContext = createContext({
    currentRowData :{}
})

export const HCRProvider = ({ children }) => {
    const [currentRowData, setcurrentRowData] = useState({data : {abc : 1}});
    const value = { currentRowData, setcurrentRowData  }
    return(
        <HCRContext.Provider value={value}>
            {children}
        </HCRContext.Provider>
    )
}