import { agentApiPath, servicePath } from "constants/defaultValues";
// import { KNOWLARITY_CONFIGS } from "helpers/Utils";
import {
  // call,
  put,
  takeEvery,
  // takeLatest,
  // all,
  // fork,
} from "redux-saga/effects";
import {
  bearerRequest,
  // knowlarityPostApi,
  request,
} from "services/requests.services";

function* getUserData(action) {
  try {
    const data = yield bearerRequest(
      "GET",
      `${agentApiPath}/users?page=${action.state.page}`
    );
    yield put({ type: "USERS_GET_LIST_SUCCESS", data: data });
  } catch (error) {
    yield put({
      type: "USERS_GET_LIST_FAILED",
      message: "Failed to get data !",
    });
  }
}

function* getStatusBucket() {
  try {
    const data = yield request(
      "GET",
      `${servicePath}/insurance/getStatusBuckets`
    );
    yield put({ type: "USER_GET_STATUS_BUCKET_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "USER_GET_STATUS_BUCKET_FAILED",
      message: "Failed to get data !",
    });
  }
}

function* getAssignUser() {
  try {
    const data = yield bearerRequest(
      "GET",
      `${agentApiPath}/assignUser`
    );
    yield put({ type: "USER_GET_ASSIGN_USER_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "USER_GET_ASSIGN_USER_FAILED",
      message: "Failed to get data !",
    });
  }
}

function* getAssignExecutive() {
  try {
    const data = yield bearerRequest(
      "GET",
      `${agentApiPath}/assignLegalExecutive`
    );
    yield put({ type: "GET_ASSIGN_LEGAL_EXECUTIVE_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "GET_ASSIGN_LEGAL_EXECUTIVE_FAILED",
      message: "Failed to get data !",
    });
  }
}

function* getAssignSubExecutive() {
    try {
        const data = yield bearerRequest(
          "GET",
          `${agentApiPath}/getLegalSubExecutive`
        );
        yield put({ type: "USER_GET_LEGAL_SUBEXECUTIVE_SUCCESS", data: data.data });
      } catch (error) {
        yield put({
          type: "USER_GET_LEGAL_SUBEXECUTIVE_FAILED",
          message: "Failed to get data !",
        });
      }
    
}


function* updateUser(action) {
    try {
        const data = yield bearerRequest(
          "POST",
          `${agentApiPath}/updatedStatus`,
          {...action.state}
        );

        yield put({ type: "USERS_GET_LIST", state: { page: action?.currentPage } });
        yield put({ type: "USER_UPDATE_USER_SUCCESS", data: data.message });
      } catch (error) {
        yield put({
          type: "USER_UPDATE_USER_FAILED",
          message: "Failed to get data !",
        });
      }

}

function* addUser(action) {
  try {
    const data = yield bearerRequest(
      "POST",
      `${agentApiPath}/addUser`,
      {...action.state}
    );
    yield put({ type: "USERS_GET_LIST", state: { page: action?.currentPage } });
    yield put({ type: "USER_ADD_USER_SUCCESS", data: data });
  } catch (error) {
    yield put({
      type: "USER_ADD_USER_FAILED",
      message: "Failed to get data !",
    });
  }

}

function* updatePassword(action) {
  try {
    const data = yield bearerRequest(
      "POST",
      `${agentApiPath}/updatedPassword`,
      {...action.state}
    );
    yield put({ type: "USER_UPDATE_PASSWORD_SUCCESS", data: data.message });
  } catch (error) {
    yield put({
      type: "USER_UPDATE_PASSWORD_FAILED",
      message: "Failed to Update !",
    });
  }
}

export default function* userSaga() {
  yield takeEvery("USERS_GET_LIST", getUserData);
  yield takeEvery("USER_GET_STATUS_BUCKET", getStatusBucket);
  yield takeEvery("USER_GET_ASSIGN_USER", getAssignUser);
  yield takeEvery("GET_ASSIGN_LEGAL_EXECUTIVE", getAssignExecutive);
  yield takeEvery("USER_GET_LEGAL_SUBEXECUTIVE", getAssignSubExecutive)
  yield takeEvery("USER_UPDATE_USER", updateUser)
  yield takeEvery("USER_ADD_USER", addUser)
  yield takeEvery("USER_UPDATE_PASSWORD",updatePassword)
}