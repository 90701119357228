import { log, toast } from "helpers/Utils";
import { CLAIM_FILING_DOWNLOAD_REPORT, CLAIM_FILING_DOWNLOAD_REPORT_FAILED, CLAIM_FILING_DOWNLOAD_REPORT_SUCCESS, CLAIM_FILING_GET, CLAIM_FILING_GET_FAILED, CLAIM_FILING_GET_SUCCESS } from "./Action";

const initialState = {
  data: {},
  reportData: {},
  message: "",
  loading: false,
  reportLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLAIM_FILING_GET:
      return { ...state, loading: true };

    case CLAIM_FILING_GET_SUCCESS:
      log("LIST", action.data);
      return { ...state, loading: false, data: action?.data };

    case CLAIM_FILING_GET_FAILED:
      toast("Failed to fetch claim filings", "", "error");
      return { ...state, loading: false };

    case CLAIM_FILING_DOWNLOAD_REPORT:
      return { ...state, reportLoading: true };

    case CLAIM_FILING_DOWNLOAD_REPORT_SUCCESS:
      return { ...state, reportLoading: false, reportData: action?.data };

    case CLAIM_FILING_DOWNLOAD_REPORT_FAILED:
      toast("Failed to download report", "", "error");
      return { ...state, reportLoading: false };

    default:
      return { ...state };
  }
};
