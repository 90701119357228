import { DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED, DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS, GET_HCR_ALL_STATUS_DATA, GET_HCR_ALL_STATUS_DATA_FAILED, GET_HCR_ALL_STATUS_DATA_SUCCESS, INVOICE_PROCESSING_FAILED } from "./action";
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
// import axios from "axios";
// import { getCurrentUser } from "helpers/Utils";
import { bearerRequest, request } from "services/requests.services";
import { bucketCount } from "constants/formValues";
import { agentApiPath, servicePath } from "constants/defaultValues";



function getAllBucketData(type) {
  return request(
    "POST",
    `${servicePath}/lead/countstatuswise`,
    {
      user_id: null,
      KeyRefresh: false,
      ...type,
    }
  );
}

function getAllLeadMarketing(type) {
  return request(
    "POST",
    `${servicePath}/lead/leadMarketingChannelCount`,
    {
      user_id: null,
      KeyRefresh: false,
      ...type,
    }
  );
}

function getLeadExpertData(state) {
  return request(
    "POST",
    `${servicePath}/lead/leadExpertCount`,
    {
        ...state
    }
  );
}

function getBucketCount(state) {
  return request(
    "POST",
    `${servicePath}/insurance/getBucketCount`,
    {...bucketCount,...state}
  );
}

function* getAllBucket(action) {
  try {
    const data = yield call(() => getAllBucketData({ ...action.state, dailyAll: "Total" }));
    yield put({ type: "ALL_LEAD_BUCKET_SUCCESS", data: data?.data });
  } catch (error) {
    yield put({ type: "ALL_LEAD_BUCKET_FAILED", message: "Failed to fetch" });
  }
}

function* getTodayLeadBucket(action) {
    try {
    const data = yield call(() => getAllBucketData({ ...action.state, dailyAll: "Daily"}));
    yield put({ type: "TODAY_LEAD_BUCKET_SUCCESS", data: data?.data });
  } catch (error) {
    yield put({ type: "TODAY_LEAD_BUCKET_FAILED", message: "Failed to fetch" });
  }
}

function* getMonthlyLeadBucket(action) {
  try {

    const data = yield call(() => getAllLeadMarketing({ ...action.state, dailyAll: "Monthly" }));
    yield put({
      type: "MONTHLY_LEAD_BUCKET_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "MONTHLY_LEAD_BUCKET_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getLeadExpert(action) {
  try {
    const data = yield call(() => getLeadExpertData({...action.state}));
    yield put({
      type: "LEAD_EXPERT_BUCKET_SUCCESS",
      data: data?.data?.allExperts,
    });
  } catch (error) {
    yield put({
      type: "LEAD_EXPERT_BUCKET_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getBucketCountFunc(action) {
  try {
    const data = yield call(() => getBucketCount({...action.state}));
    yield put({
      type: "LEAD_BUCKET_COUNT_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "LEAD_BUCKET_COUNT_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getComplaintFunc(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getComplaintCount`,
      {
        ...action.state
    }
    );

    yield put({
      type: "COMPLAINT_DASHBOARD_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "COMPLAINT_DASHBOARD_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getB2CRegistrationFunc(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getRegistrationCount`,
      {...action.state}
    );

    yield put({
      type: "B2C_REGISTRATION_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_REGISTRATION_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getB2CRegistrationCasesFunc(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getInvoiceSettledCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_REGISTRATION_CASES_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_REGISTRATION_CASES_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getPartnerRegistration(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getPartnerRegistrationCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_REGISTRATION_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_REGISTRATION_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getb2cResolution(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getResolvedCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_RESOLUTION_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_RESOLUTION_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getPartnerResolution(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getPartnerResolvedCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_RESOLUTION_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_RESOLUTION_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getb2cSattled(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getInvoiceSettledCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_SATTLED_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_SATTLED_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getPartnerSattled(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getInvoiceSettledCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_SATTLED_CASES_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_SATTLED_CASES_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getb2cInvoiceRaised(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getInvoiceSettledCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_INVOICE_RAISED_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_INVOICE_RAISED_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getPartnerInvoiceRaised(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getInvoiceSettledCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_INVOICE_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_INVOICE_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getLegalPartner(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getPartnerCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "LEGAL_PARTNER_DASHBOARD_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "LEGAL_PARTNER_DASHBOARD_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getB2cOMBCount(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getOmdCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_OMBUDSMAN_COUNT_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_OMBUDSMAN_COUNT_FAILED",
      message: "Failed to fetch",
    });
  }
}
function* getPartnerOMBCount(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getOmdCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_OMBUDSMAN_COUNT_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_OMBUDSMAN_COUNT_FAILED",
      message: "Failed to fetch",
    });
  }
}
function* getOMBnewB2cCases(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getComplaintFormCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "NEW_OMBUDSMAN_COUNT_B2C_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "NEW_OMBUDSMAN_COUNT_B2C_FAILED",
      message: "Failed to fetch",
    });
  }
}
function* getOMBnewPartnerCount(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getNewOmdCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "NEW_OMBUDSMAN_COUNT_PARTNER_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "NEW_OMBUDSMAN_COUNT_PARTNER_FAILED",
      message: "Failed to fetch",
    });
  }
}
function* getOMBresendB2cCases(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/resendCasesCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "OMBUDSMAN_RESEND_CASES_B2C_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "OMBUDSMAN_RESEND_CASES_B2C_FAILED",
      message: "Failed to fetch",
    });
  }
}
function* getOMBresendPartnerCases(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/resendCasesCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "OMBUDSMAN_RESEND_CASES_PARTNER_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "OMBUDSMAN_RESEND_CASES_PARTNER_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getb2cMailing(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getMailingCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "B2C_MAILING_COUNT_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "B2C_MAILING_COUNT_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getPartnerMailing(action) {
  try {
    const data = yield request(
      "POST",
      `${servicePath}/partnerApi/getMailingCount`,
      {
        ...action.state
      }
    );

    yield put({
      type: "PARTNER_MAILING_COUNT_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "PARTNER_MAILING_COUNT_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getActiveUsers() {
  try {
    const data = yield bearerRequest(
      "GET",
      `${agentApiPath}/activeUsers`
    );
    yield put({
      type: "GET_ACTIVE_USER_SUCCESS",
      data: data?.user_data,
    });
  } catch (error) {
    yield put({
      type: "GET_ACTIVE_USER_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getStates() {
  try {
    const data = yield request(
      "GET",
      `${servicePath}/ombudsman/state`
    );

    yield put({
      type: "GET_STATES_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "GET_STATES_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getExprtLead(action) {
  try {
    const {user_id:userId, ...other} = action.state
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getExpertLeads`,
      {...other, userId}
    );
    yield put({
      type: "DASHBOARD_GET_EXPERTS_LEADS_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "DASHBOARD_GET_EXPERTS_LEADS_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* getExpertPartner(action) {
  try {
    const {user_id:userId, ...other} = action.state
    const data = yield request(
      "POST",
      `${servicePath}/insurance/getExpertPartnerLeads`,
      {...other, userId}
    );
    yield put({
      type: "DASHBOARD_GET_EXPERT_PARTNER_LEADS_SUCCESS",
      data: data?.data,
    });
  } catch (error) {
    yield put({
      type: "DASHBOARD_GET_EXPERT_PARTNER_LEADS_FAILED",
      message: "Failed to fetch",
    });
  }
}

function* invoiceProcessing(action) {
  try {
    const data = yield request("POST",`${servicePath}/insurance/getInvoiceProcessing`,action.state)
    yield put({type:"INVOICE_PROCESSING_SUCCESS",data:data.data})
  } catch (error) {
    yield put({
      type:INVOICE_PROCESSING_FAILED,
      message : error.message
    })
  }
}

function* getPartnerLeadCount(action) {
  try {
    const data = yield request('POST', `${servicePath}/lead/partnercountstatuswise`, action.state);
    yield put({type: "PARTNER_LEAD_BUCKET_COUNT_SUCCESS", data: data.data})
  } catch (error) {
    yield put({
      type:"PARTNER_LEAD_BUCKET_COUNT_FAILED",
      message:error.message
    })
  }
}

function* getExecutiveData(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/executiveLeads`, action.state);
    yield put({type: DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS, data : data.data});
  } catch (error) {
      yield put({
        type:DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED,
        message : error.message
      })
  }
}

function* getHCRDashboardData(action) {
  try {
    const data = yield request("POST", `${servicePath}/hcr/getDashboardData`, action.state);
    yield put({
      type : GET_HCR_ALL_STATUS_DATA_SUCCESS,
      data : data.data
    });
  } catch (error) {
    yield put({
      type : GET_HCR_ALL_STATUS_DATA_FAILED,
      message : "Error in fetching dashboard data !"
    })
  }
}

export default function* mySaga() {
  yield takeEvery("GET_ACTIVE_USER", getActiveUsers);
  yield takeEvery("GET_STATES", getStates);

  yield takeEvery("ALL_LEAD_BUCKET", getAllBucket);
  yield takeEvery("TODAY_LEAD_BUCKET", getTodayLeadBucket);
  yield takeEvery("MONTHLY_LEAD_BUCKET", getMonthlyLeadBucket);
  yield takeEvery("LEAD_EXPERT_BUCKET", getLeadExpert);
  yield takeEvery("LEAD_BUCKET_COUNT", getBucketCountFunc);
  yield takeEvery("COMPLAINT_DASHBOARD", getComplaintFunc);
  yield takeEvery("B2C_REGISTRATION", getB2CRegistrationFunc);
  yield takeEvery("B2C_REGISTRATION_CASES", getB2CRegistrationCasesFunc);
  yield takeEvery("PARTNER_REGISTRATION", getPartnerRegistration);
  yield takeEvery("B2C_RESOLUTION", getb2cResolution);
  yield takeEvery("PARTNER_RESOLUTION", getPartnerResolution);
  yield takeEvery("B2C_SATTLED", getb2cSattled);
  yield takeEvery("PARTNER_SATTLED_CASES", getPartnerSattled);
  yield takeEvery("B2C_INVOICE_RAISED", getb2cInvoiceRaised);
  yield takeEvery("PARTNER_INVOICE", getPartnerInvoiceRaised);
  yield takeEvery("LEGAL_PARTNER_DASHBOARD", getLegalPartner);

  yield takeEvery("B2C_OMBUDSMAN_COUNT", getB2cOMBCount);
  yield takeEvery("PARTNER_OMBUDSMAN_COUNT", getPartnerOMBCount);
  yield takeEvery("NEW_OMBUDSMAN_COUNT_B2C", getOMBnewB2cCases);
  yield takeEvery("NEW_OMBUDSMAN_COUNT_PARTNER", getOMBnewPartnerCount);
  yield takeEvery("OMBUDSMAN_RESEND_CASES_B2C", getOMBresendB2cCases);
  yield takeEvery("OMBUDSMAN_RESEND_CASES_PARTNER", getOMBresendPartnerCases);

  yield takeEvery("B2C_MAILING_COUNT", getb2cMailing);
  yield takeEvery("PARTNER_MAILING_COUNT", getPartnerMailing);
  yield takeEvery("DASHBOARD_GET_EXPERTS_LEADS", getExprtLead)
  yield takeEvery("DASHBOARD_GET_EXPERT_PARTNER_LEADS", getExpertPartner);
  yield takeEvery("PARTNER_LEAD_BUCKET_COUNT", getPartnerLeadCount)
  yield takeLatest("DASHBOARD_EXECUTIVE_PRODUCTIVITY", getExecutiveData)
  yield takeLatest("INVOICE_PROCESSING_START",invoiceProcessing);
  yield takeLatest(GET_HCR_ALL_STATUS_DATA, getHCRDashboardData)
}
