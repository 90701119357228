export const PARTNER_LEAD_BY_STATUS = "PARTNER_LEAD_BY_STATUS"
export const PARTNER_LEAD_BY_STATUS_SUCCESS = "PARTNER_LEAD_BY_STATUS_SUCCESS"
export const PARTNER_LEAD_BY_STATUS_FAILED = "PARTNER_LEAD_BY_STATUS_FAILED"

export const PARTNER_LEAD_ASSIGN_USER_LIST = "PARTNER_LEAD_ASSIGN_USER_LIST"
export const PARTNER_LEAD_ASSIGN_USER_LIST_FAILED = "PARTNER_LEAD_ASSIGN_USER_LIST_FAILED"
export const PARTNER_LEAD_ASSIGN_USER_LIST_SUCCESS = "PARTNER_LEAD_ASSIGN_USER_LIST_SUCCESS"

export const PARTNER_ASSIGN_LEAD = "PARTNER_ASSIGN_LEAD"
export const PARTNER_ASSIGN_LEAD_SUCCESS = "PARTNER_ASSIGN_LEAD_SUCCESS"
export const PARTNER_ASSIGN_LEAD_FAILED = "PARTNER_ASSIGN_LEAD_FAILED"

export const PARTNER_ASSIGN_EXPRT_LIST = "PARTNER_ASSIGN_EXPRT_LIST"
export const PARTNER_ASSIGN_EXPRT_LIST_SUCCESS = "PARTNER_ASSIGN_EXPRT_LIST_SUCCESS"
export const PARTNER_ASSIGN_EXPRT_LIST_FAILED = "PARTNER_ASSIGN_EXPRT_LIST_FAILED"

export const PARTNER_LEAD_ASSIGN_EXPERT = "PARTNER_LEAD_ASSIGN_EXPERT"
export const PARTNER_LEAD_ASSIGN_EXPERT_SUCCESS = "PARTNER_LEAD_ASSIGN_EXPERT_SUCCESS"
export const PARTNER_LEAD_ASSIGN_EXPERT_FAILED = "PARTNER_LEAD_ASSIGN_EXPERT_FAILED"

export const PARTNER_GET_CALL_LIST = "PARTNER_GET_CALL_LIST"
export const PARTNER_GET_CALL_LIST_SUCCESS = "PARTNER_GET_CALL_LIST_SUCCESS"
export const PARTNER_GET_CALL_LIST_FAILED = "PARTNER_GET_CALL_LIST_FAILED"

export const PARTNER_LEAD_UPDATE = "PARTNER_LEAD_UPDATE"
export const PARTNER_LEAD_UPDATE_SUCCESS = "PARTNER_LEAD_UPDATE_SUCCESS"
export const PARTNER_LEAD_UPDATE_FAILED = "PARTNER_LEAD_UPDATE_FAILED"

export const PARTNER_LEAD_GROUPS = "PARTNER_LEAD_GROUPS"
export const PARTNER_LEAD_GROUPS_SUCCESS = "PARTNER_LEAD_GROUPS_SUCCESS"
export const PARTNER_LEAD_GROUPS_FAILED = "PARTNER_LEAD_GROUPS_FAILED"

export const PARTNER_LEAD_BY_SEARCH = "PARTNER_LEAD_BY_SEARCH"
export const PARTNER_LEAD_BY_SEARCH_SUCCESS = "PARTNER_LEAD_BY_SEARCH_SUCCESS"
export const PARTNER_LEAD_BY_SEARCH_FAILED = "PARTNER_LEAD_BY_SEARCH_FAILED"

export const PARTNER_LEAD_GET_COMMENT = "PARTNER_LEAD_GET_COMMENT"
export const PARTNER_LEAD_GET_COMMENT_SUCCESS = "PARTNER_LEAD_GET_COMMENT_SUCCESS"
export const PARTNER_LEAD_GET_COMMENT_FAILED = "PARTNER_LEAD_GET_COMMENT_FAILED"

export const PARTNER_LEAD_ADD_COMMENT = "PARTNER_LEAD_ADD_COMMENT"
export const PARTNER_LEAD_ADD_COMMENT_SUCCESS = "PARTNER_LEAD_ADD_COMMENT_SUCCESS"
export const PARTNER_LEAD_ADD_COMMENT_FAILED = "PARTNER_LEAD_ADD_COMMENT_FAILED"

export const PARTNER_LEAD_GET_DOCUMENT = "PARTNER_LEAD_GET_DOCUMENT"
export const PARTNER_LEAD_GET_DOCUMENT_SUCCESS = "PARTNER_LEAD_GET_DOCUMENT_SUCCESS"
export const PARTNER_LEAD_GET_DOCUMENT_FAILED = "PARTNER_LEAD_GET_DOCUMENT_FAILED"

export const GET_PARTNER_LEAD_DETAIL = "GET_PARTNER_LEAD_DETAIL"
export const GET_PARTNER_LEAD_DETAIL_SUCCESS = "GET_PARTNER_LEAD_DETAIL_SUCCESS"
export const GET_PARTNER_LEAD_DETAIL_FAILED = "GET_PARTNER_LEAD_DETAIL_FAILED"