import { agentApiPath, partnerServicePath, servicePath } from "constants/defaultValues";
import { getCurrentUser } from "helpers/Utils";
import { call, put, takeEvery } from "redux-saga/effects";
import { partnerRequest } from "services/requests.services";

function* getLeads(action) {
    try {
        const {status, page, size, assign, group, contract} = action.state
        const data = yield partnerRequest("GET", `${partnerServicePath}/lead/all?page=${page}&size=${size}&status=${status}&assigneeId=${assign}&groupName=${group}&contract=${contract}`)
        const dataCount = yield partnerRequest("GET", `${partnerServicePath}/lead/count/all?assigneeId=${assign}&status=${status}&contract=${contract ? contract : false}`)
        yield put ({type:"PARTNER_LEAD_BY_STATUS_SUCCESS", data:data.data?.data, dataCount : dataCount?.data?.data})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_BY_STATUS_FAILED",
            message:error.message,
        })
    }
}

function* getAssignUser() {
    try {
        const {data} = yield partnerRequest("GET", `${agentApiPath}/assignUserPartner`)
        yield put ({type:"PARTNER_LEAD_ASSIGN_USER_LIST_SUCCESS", data:data.data})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_ASSIGN_USER_LIST_FAILED",
            message:error.message,
        })
    }
}

function* assignLead(action) {
    try {
        const res = yield partnerRequest("PUT", `${partnerServicePath}/lead/assign/user`, {...action.state})
        yield put ({type:"PARTNER_ASSIGN_LEAD_SUCCESS", message:res.msg})
    } catch (error) {
        yield put({
            type:"PARTNER_ASSIGN_LEAD_FAILED",
            message:error.message,
        })
    }
}

function* getExperts() {
    try {
        const {data} = yield partnerRequest("GET", `${agentApiPath}/assignExpert`)
        yield put ({type:"PARTNER_ASSIGN_EXPRT_LIST_SUCCESS", data:data})
    } catch (error) {
        yield put({
            type:"PARTNER_ASSIGN_EXPRT_LIST_FAILED",
            message:error.message,
        })
    }
}

function* assignToExpert(action) {
    try {
        const res = yield partnerRequest("PUT", `${partnerServicePath}/lead/assign/expert`, {...action.state})
        yield put ({type:"PARTNER_LEAD_ASSIGN_EXPERT_SUCCESS", message:res.message})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_ASSIGN_EXPERT_FAILED",
            message:error.message,
        })
    }
}

function* getCallList() {
    try {
        const {data} = yield partnerRequest("POST", `${servicePath}/insurance/getCallList`, {
            "headers": {}
        })
        yield put ({type:"PARTNER_GET_CALL_LIST_SUCCESS", data:data.data})
    } catch (error) {
        yield put({
            type:"PARTNER_GET_CALL_LIST_FAILED",
            message:error.message,
        })
    }
}

function* updateLead(action) {
    try {
        const {paginationData, ...other} = action.state
        const res = yield partnerRequest("PUT", `${partnerServicePath}/lead/update`, {...other});
        yield put ({type:"PARTNER_LEAD_UPDATE_SUCCESS", message:res.message})

        if (paginationData) {
            yield call(getLeads, {state : paginationData})
        }

    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_UPDATE_FAILED",
            message:error.message,
        })
    }
}

function* getGroups(action) {
    try {
        const {data} = yield partnerRequest("GET", `${partnerServicePath}/lead/group/names?authorization=${getCurrentUser()?.data?.token}`, {...action.state})
        yield put ({type:"PARTNER_LEAD_GROUPS_SUCCESS", data:data?.data})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_GROUPS_FAILED",
            message:error.message,
        })
    }
}

function* getSearchData(action) {

    try {
        const {page, size, search} = action.state
        const {data} = yield partnerRequest("GET", `${partnerServicePath}/lead/search?search=${search}&page=${page}&size=${size}`, {...action.state})
            yield put ({type:"PARTNER_LEAD_BY_SEARCH_SUCCESS", data:data?.data?.data, dataCount:data?.data?.count})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_BY_SEARCH_FAILED",
            message:error.message,
        })
    }
}

function* getLeadComments(action) {
    try {
        const {data} = yield partnerRequest("GET", `${partnerServicePath}/lead/comment/get?leadId=${action.state?.id}`)
        yield put ({type:"PARTNER_LEAD_GET_COMMENT_SUCCESS", data:data?.data})
    } catch (error) {
        console.log(error)
        yield put({
            type:"PARTNER_LEAD_GET_COMMENT_FAILED",
            message:error.message,
        })
    }
}

function* addComment(action) {
    try {
        let payload = {
            adminPortalUserId: getCurrentUser()?.data?.user_id || "adminPortal",
            comment:action.state.comment
        }
        if (action.state?.private) {
            payload["private"] = action.state?.private
        }
        const {data} = yield partnerRequest("PUT", `${partnerServicePath}/lead/comment/add/${action.state?.id}`, payload)
        yield put ({type:"PARTNER_LEAD_ADD_COMMENT_SUCCESS", message:data?.message})
        yield call(getLeadComments, action)
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_ADD_COMMENT_FAILED",
            message:error.message,
        })
    }
}

function* getLeadDoc(action) {
    try {
        const {data} = yield partnerRequest("GET", `${partnerServicePath}/lead/single/${action.state?.id}`)
        yield put ({type:"PARTNER_LEAD_GET_DOCUMENT_SUCCESS", data:data?.data})
    } catch (error) {
        yield put({
            type:"PARTNER_LEAD_GET_DOCUMENT_FAILED",
            message:error.message,
        })
    }
}

function* getLeadData(action) {
    try {
        const {data} = yield partnerRequest("GET", `${partnerServicePath}/lead/single/${action.state?.id}`)
        yield put ({type:"GET_PARTNER_LEAD_DETAIL_SUCCESS", data:data?.data})
    } catch (error) {
        yield put({
            type:"GET_PARTNER_LEAD_DETAIL_FAILED",
            message:error.message,
        })
    }
}

export default function* partnerLead() {
    yield takeEvery("PARTNER_LEAD_BY_STATUS", getLeads);
    yield takeEvery("PARTNER_LEAD_ASSIGN_USER_LIST", getAssignUser);
    yield takeEvery("PARTNER_ASSIGN_LEAD", assignLead);
    yield takeEvery("PARTNER_ASSIGN_EXPRT_LIST", getExperts);
    yield takeEvery("PARTNER_LEAD_ASSIGN_EXPERT", assignToExpert);
    yield takeEvery("PARTNER_GET_CALL_LIST", getCallList);
    yield takeEvery("PARTNER_LEAD_UPDATE", updateLead);
    yield takeEvery("PARTNER_LEAD_GROUPS", getGroups);
    yield takeEvery("PARTNER_LEAD_BY_SEARCH", getSearchData);
    yield takeEvery("PARTNER_LEAD_GET_COMMENT", getLeadComments);
    yield takeEvery("PARTNER_LEAD_ADD_COMMENT", addComment);
    yield takeEvery("PARTNER_LEAD_GET_DOCUMENT", getLeadDoc);
    yield takeEvery("GET_PARTNER_LEAD_DETAIL", getLeadData)
}