import axios from "axios"
import { adminAccount, agentApiPath, servicePath } from "constants/defaultValues";
import { getCurrentUser, setCurrentUser } from "helpers/Utils";
import { apisURLs } from "./apisURLs.services"
import { logoutRequest, request } from "./requests.services";

export const createToken = async() => {
    return await axios({
        method: 'GET',
        url: apisURLs.createToken,
        data: {}
    }).then(res => {
        // const success = res.Status === 200 ? true : false;
        // console.log("--------------",res.data);
        const getToken = res.data.token ? res.data.token : res.data;
        localStorage.setItem('insa_agentToken', JSON.stringify(getToken));
        // window.location.reload()
    });
}

export const loginWithEmailAndPasswordApi = async ({email,password}) => {
    const apiEndpoint = (adminAccount.includes(email.trim()) ? "adminLogin" : "agentLogin");
    return await axios({
        method:"POST",
        url:apisURLs[apiEndpoint],
        data:{
            email,
            password
        } 
    }).then(res => {
        createToken();
        const success = res.data?.success || (res.data?.Status === "200" ? true : false);
        const getRole = res.data?.data?.userType;
        const role = ((getRole == 'admin') ? 1 : 0);
        res = {...res.data, success, role};
        success && setCurrentUser(res);
        return res;
    });
}

export const logOutExecutive = async () => {
    const user = getCurrentUser();
    if (user.data.userType === "admin") {
        await request("POST", servicePath +  "/admin/logout", {});
        return;
    }
    await logoutRequest("POST", agentApiPath +  "/logout", {});
    return;
}