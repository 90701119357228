import { getCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGIN_USER_ERROR,
} from '../contants';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => { 
  getCurrentUser()
  return ({
  type: LOGOUT_USER,
  payload: { history },
})};

export const isAuthenticated = (isLogin) => ({
  type: "AUTHENTICATE_USER",
  payload :{isLogin}
})