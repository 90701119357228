export const GET_HOSPITALS_LIST = "GET_HOSPITALS_LIST";
export const GET_HOSPITALS_LIST_SUCCESS = "GET_HOSPITALS_LIST_SUCCESS";
export const GET_HOSPITALS_LIST_FAILURE = "GET_HOSPITALS_LIST_FAILURE";

export const ADD_HOSPITALS_LIST = "ADD_HOSPITALS_LIST";
export const ADD_HOSPITALS_LIST_SUCCESS = "ADD_HOSPITALS_LIST_SUCCESS";
export const ADD_HOSPITALS_LIST_FAILURE = "ADD_HOSPITALS_LIST_FAILURE";

export const UPDATE_HOSPITALS_LIST = "UPDATE_HOSPITALS_LIST";
export const UPDATE_HOSPITALS_LIST_SUCCESS = "UPDATE_HOSPITALS_LIST_SUCCESS";
export const UPDATE_HOSPITALS_LIST_FAILURE = "UPDATE_HOSPITALS_LIST_FAILURE";
