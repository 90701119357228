import { GET_INSA_ENGINE_DATA_SUCCESS, GET_INSA_ENGINE_DATA_FAILED, GET_INSA_ENGINE_DATA_START, GET_INSA_ENGINE_DATA_BY_SEARCH_START, GET_INSA_ENGINE_DATA_BY_SEARCH_SUCCESS, GET_INSA_ENGINE_DATA_BY_SEARCH_FAILED } from "./Action"

const initValue = {
    businessData: [],
    loading: false,
    searchLoading: false,
    totalRecords: 0,
    currentPage: 0,
}

export default (state = initValue, action) => {
    switch (action.type) {
        case GET_INSA_ENGINE_DATA_START:
            return { ...state, loading: true }

        case GET_INSA_ENGINE_DATA_SUCCESS:
            return { ...state, businessData: action.data?.data, loading: false, totalRecords: action.data?.totalRecords, currentPage: action?.data?.currentPage };

        case GET_INSA_ENGINE_DATA_FAILED:
            return { ...state, loading: false }

        case GET_INSA_ENGINE_DATA_BY_SEARCH_START:
            return { ...state, searchLoading: true, loading: true }

        case GET_INSA_ENGINE_DATA_BY_SEARCH_SUCCESS:
            return { ...state, searchLoading: false, loading: false, businessData: action.data?.data, totalRecords: action.data?.totalRecords, currentPage: action?.data?.currentPage }

        case GET_INSA_ENGINE_DATA_BY_SEARCH_FAILED:
            return { ...state, searchLoading: false, loading: false }

        default:
            return state
    }
}