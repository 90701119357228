import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import bucket from "./Dashboard/reducer";
import botTranscript from "./BotTranscript/Reducer";
import leadReducer from "./Lead/Reducer";
import customer from "./Customer/Reducer";
import user from "./Users/Reducer";
import complaint from "./Complaint/Reducer";
import report from "./Report/Reducer";
import partnerLead from "./PartnerLead/Reducer";
import company from "./Company/Reducer";
import complaintType from "./ComplaintType/Reducer";
import customerQuery from "./CustomerQuery/Reducer";
import hospitals from "./Hospitals/Reducer";
import dropdown from "./Dropdown/Reducer";
import claimFiling from "./ClaimFiling/Reducer";
import searchComplaint from "./SearchComplaint/Reducer";
import DocumentUpload from "./DocumetUpload/reducer";
import InsaEngine from "./InsaEngine/Reducer";
import CronJobs from "./CronJons/Reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  bucket,
  botTranscript,
  leadReducer,
  customer,
  user,
  complaint,
  report,
  partnerLead,
  company,
  complaintType,
  customerQuery,
  hospitals,
  dropdown,
  claimFiling,
  searchComplaint,
  DocumentUpload,
  InsaEngine,
  CronJobs,
});

export default reducers;
