import { all, put, takeLatest, call } from "redux-saga/effects";
import { DOCUMENT_HCR_UPLOAD_VERIFY_USER, DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED, DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS, DOCUMENT_UPLOAD_DELETE_HCR_SINGLE_DOC, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS, DOCUMENT_UPLOAD_GET_SINGLE_LEAD, DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED, DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS, DOCUMENT_UPLOAD_SUBMIT_ACTION, DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED, DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS, DOCUMENT_UPLOAD_UPLOAD_FILE, DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED, DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER, DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS, DOCUMENT_UPLOAD_VERIFY_OTP, DOCUMENT_UPLOAD_VERIFY_OTP_FAILED, DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS, DOCUMENT_UPLOAD_VERIFY_USER, DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE, DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED, DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS, DOCUMENT_UPLOAD_VERIFY_USER_FAILED, DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS } from "./Action";
import { docUploadRequest } from "services/requests.services";
import { servicePath } from "constants/defaultValues";
import { log } from "helpers/Utils";

function* checkUserValid(action) {
    try {
        const data = yield docUploadRequest(
            "POST",
            `${servicePath}/lead/verifyDocumentUploadLead`,
            { leadId: action.state?.leadId }
        );
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS,
            valid: data.data ? true : false,
            leadData : data.data
        });

    } catch (error) {
        console.log(error)
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_USER_FAILED,
            valid: false,
            message: "Not a Valid Request !"
        })
    }
}

function* checkHcrUserValid(action) {
    try {
        const data = yield docUploadRequest(
            "POST",
            `${servicePath}/hcr/verifyDocumentUploadLead`,
            {},
            { hcrkey: action.state?.hcrId }
        );
        localStorage.setItem("leadId-hcr-document-upload", JSON.stringify({ hcr_key: action.state?.hcrId }));
        yield put({
            type: DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS,
            valid: data.data ? true : false,
            hcrLeadData : data.data
        });

    } catch (error) {
        console.log(error)
        yield put({
            type: DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED,
            valid: false,
            message: "Not a Valid Request !"
        })
    }
}

// function* checkUserValidManually(action) {
//     try {
//         const data = yield docUploadRequest(
//             "POST",
//             `${servicePath}/lead/verifyDocumentUploadLead`,
//             { leadId: action.state?.leadId, otp : acti }
//         );
//         yield put({
//             type: DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS,
//             valid: data.data ? true : false,
//             leadData : data.data,
//             otpVerified:true
//         });

//     } catch (error) {
//         console.log(error)
//         yield put({
//             type: DOCUMENT_UPLOAD_VERIFY_USER_FAILED,
//             valid: false,
//             message: "Not a Valid Request !"
//         })
//     }
// }

function* verifyOtpFunc(action) {
    try {
        const { phone } = action.state || {};
        const res = yield docUploadRequest(
            "POST",
            `${servicePath}/lead/docuploadverificationbyphone`,
            { phone:phone.toString() }
        )
        let token = res.data.token
        localStorage.setItem("leadId-document-upload", JSON.stringify({leadId:res.data?.leadObj?._id}));
        localStorage.setItem("doc_upload_accessor", JSON.stringify({token}));
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS,
            message: "Otp Verified !",
            isVerifyed : true,
            leadData : res.data?.leadObj
        })

    } catch (error) {
        log(error);
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_OTP_FAILED,
            message: "Invalid OTP",
            isVerifyed : false,
        })
    }
}

function* verifyOtpFuncMaually(action) { 
    try {
        const { leadId, otp } = action.state || {};
        const res = yield docUploadRequest(
            "POST",
            `${servicePath}/lead/verifyOtpDocumentUpload`,
            { leadId: leadId, otp: otp }
        )
        let token = res.data.token
        console.log("--------------------------------------------------------------fdsafsfds")
        localStorage.setItem("leadId-document-upload", JSON.stringify({leadId}));
        localStorage.setItem("doc_upload_accessor", JSON.stringify({token}));
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS,
            message: "Otp Verified !",
            isVerifyed : true
        })

    } catch (error) {
        log(error);
        yield put({
            type: DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED,
            message: "Invalid OTP",
            isVerifyed : false
        })
    }
}

function* getDocumentListFunc(action) {
    try {
         const  { leadId } = action.state;
        const res = yield docUploadRequest(
            "POST",
            `${servicePath}/app/version2/document/list`,
            { leadId }
        )
        yield put({
            type : DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS,
            data : res.data,
            message : "Success !"
        })
    } catch (error) {
        log(error);
        yield put({
            type : DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED,
            message : "Failed to get Document List !"
        })
    }
}

function* uploadDocSaga(action) {
    try {
        const  { data, leadId } = action.state;
        yield put({
            type : DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER
        })
        if (data) {
            yield put({
                type : DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS,
                data : data,
                message : "Success !"
            })
        }
        yield call(getLeadDataAsync,{state : {leadId}})

    } catch (error) {
        console.log(error);
        yield put({
            type : DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED,
            message : "Failed to Upload Document !"
        })
    }
}

function* deleteDocumentAsync(action) {
    try {
        const { leadId } = action.state || {}
        const res = yield docUploadRequest(
            "POST",
            `${servicePath}/filtration/common/deleteDocument/${leadId}`,
            { documentName : action.state.docName }
        )
         yield put({
            type:DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS,
            message : res.message
        })
        yield call(getLeadDataAsync,{state : {leadId}})
    } catch (error) {
        console.log(error);
        yield put({
            type : DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED,
            message : "Failed to delete Dcument !"
        })
    }
}

function* deleteHcrDocumentAsync(action) {
    try {
        const { hcr_key, typeId, documentId } = action.state || {}
        yield docUploadRequest(
            "DELETE",
            `${servicePath}/hcr/uploadDoc/${typeId}/${documentId}`,
            { documentName : action.state.docName },
            { hcrkey: hcr_key }
        )
        yield put({
            type : DOCUMENT_HCR_UPLOAD_VERIFY_USER,
            state: { hcrId : hcr_key }
        })
    } catch (error) {
        console.log(error);
    }
}

function* getLeadDataAsync(action) {
    try {
        const {leadId} = action.state
        const res = yield docUploadRequest(
            "GET",
            `${servicePath}/lead/getSingleLead/${leadId}`,
        )

        yield put({
            type : DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS,
            data : res.data
        })
        yield put({
            type: DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS,
            data : res.data
        })

    } catch (error) {
        log(error)
        yield put({
            type : DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED,
            message : "Failed to get Lead Data !"
        })
    }
}

function* submitFormAsync(action) {
    try {
        const { leadId } = action.state;
        yield docUploadRequest(
            "POST",
            `${servicePath}/lead/moveToDocumentBifurcationPending`,
            {leadId}
        )
        yield put({
            type:DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS,
            message : "Document Upload Successfully !"
        })
    } catch (error) {
        log(error);
        yield put({
            type:DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED,
            message : "Failed to Submit Data !"
        })
    }
}

function* chackUser() {
    return yield takeLatest(DOCUMENT_UPLOAD_VERIFY_USER, checkUserValid)
}

function* chackHcrUser() {
    return yield takeLatest(DOCUMENT_HCR_UPLOAD_VERIFY_USER, checkHcrUserValid)
}

function* verifyOtp() {
    return yield takeLatest(DOCUMENT_UPLOAD_VERIFY_OTP, verifyOtpFunc);
}

function* verifyByOtpManual() {
    return yield takeLatest(DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE, verifyOtpFunc)
}

function* manualOTP() {
    return yield takeLatest("DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_MANUAL", verifyOtpFuncMaually)
}

function* getDocumentList() {
    return yield takeLatest(DOCUMENT_UPLOAD_GET_DOCUMENT_LIST, getDocumentListFunc);
}

function* uploadDoc() {
    return yield takeLatest(DOCUMENT_UPLOAD_UPLOAD_FILE, uploadDocSaga)
}

function* deleteDocument() {
    return yield takeLatest(DOCUMENT_UPLOAD_DELETE_SINGLE_DOC, deleteDocumentAsync)
}

function* deleteHcrDocument() {
    return yield takeLatest(DOCUMENT_UPLOAD_DELETE_HCR_SINGLE_DOC, deleteHcrDocumentAsync)
}

function* getLeadData () {
    return yield takeLatest(DOCUMENT_UPLOAD_GET_SINGLE_LEAD, getLeadDataAsync)
}

function* submitForm() {
    return yield takeLatest(DOCUMENT_UPLOAD_SUBMIT_ACTION, submitFormAsync)
}

export default function* DocumentUploadSaga() {
    yield all([call(chackUser), call(chackHcrUser), call(deleteHcrDocument), call(verifyOtp), call(getDocumentList), call(uploadDoc), call(deleteDocument), call(getLeadData), call(submitForm), call(verifyByOtpManual), call(manualOTP)])
}