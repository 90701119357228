// import data from "data/notifications";
import { getCountB2C } from "helpers/dashboardHelper";
import {
  ALL_LEAD_BUCKET,
  ALL_LEAD_BUCKET_SUCCESS,
  TODAY_LEAD_BUCKET,
  TODAY_LEAD_BUCKET_SUCCESS,
  TODAY_LEAD_BUCKET_FAILED,
  MONTHLY_LEAD_BUCKET,
  MONTHLY_LEAD_BUCKET_SUCCESS,
  MONTHLY_LEAD_BUCKET_FAILED,
  LEAD_EXPERT_BUCKET_SUCCESS,
  LEAD_EXPERT_BUCKET,
  LEAD_EXPERT_BUCKET_FAILED,
  LEAD_BUCKET_COUNT,
  LEAD_BUCKET_COUNT_SUCCESS,
  LEAD_BUCKET_COUNT_FAILED,
  COMPLAINT_DASHBOARD,
  COMPLAINT_DASHBOARD_SUCCESS,
  COMPLAINT_DASHBOARD_FAILED,
  B2C_REGISTRATION_SUCCESS,
  B2C_REGISTRATION_FAILED,
  B2C_REGISTRATION_CASES,
  B2C_REGISTRATION_CASES_SUCCESS,
  B2C_REGISTRATION_CASES_FAILED,
  PARTNER_REGISTRATION,
  PARTNER_REGISTRATION_SUCCESS,
  PARTNER_REGISTRATION_FAILED,
  B2C_REGISTRATION,
  B2C_RESOLUTION,
  B2C_RESOLUTION_SUCCESS,
  B2C_RESOLUTION_FAILED,
  PARTNER_RESOLUTION,
  PARTNER_RESOLUTION_SUCCESS,
  PARTNER_RESOLUTION_FAILED,
  B2C_SATTLED,
  B2C_SATTLED_SUCCESS,
  B2C_SATTLED_FAILED,
  PARTNER_SATTLED_CASES,
  PARTNER_SATTLED_CASES_SUCCESS,
  PARTNER_SATTLED_CASES_FAILED,
  B2C_INVOICE_RAISED,
  B2C_INVOICE_RAISED_SUCCESS,
  B2C_INVOICE_RAISED_FAILED,
  PARTNER_INVOICE,
  PARTNER_INVOICE_SUCCESS,
  PARTNER_INVOICE_FAILED,
  LEGAL_PARTNER_DASHBOARD,
  LEGAL_PARTNER_DASHBOARD_SUCCESS,
  LEGAL_PARTNER_DASHBOARD_FAILED,
  PARTNER_OMBUDSMAN_COUNT,
  PARTNER_OMBUDSMAN_COUNT_SUCCESS,
  PARTNER_OMBUDSMAN_COUNT_FAILED,
  NEW_OMBUDSMAN_COUNT_B2C,
  NEW_OMBUDSMAN_COUNT_B2C_SUCCESS,
  NEW_OMBUDSMAN_COUNT_B2C_FAILED,
  NEW_OMBUDSMAN_COUNT_PARTNER,
  NEW_OMBUDSMAN_COUNT_PARTNER_SUCCESS,
  NEW_OMBUDSMAN_COUNT_PARTNER_FAILED,
  OMBUDSMAN_RESEND_CASES_B2C,
  OMBUDSMAN_RESEND_CASES_B2C_SUCCESS,
  OMBUDSMAN_RESEND_CASES_B2C_FAILED,
  OMBUDSMAN_RESEND_CASES_PARTNER,
  OMBUDSMAN_RESEND_CASES_PARTNER_SUCCESS,
  OMBUDSMAN_RESEND_CASES_PARTNER_FAILED,
  B2C_OMBUDSMAN_COUNT,
  B2C_OMBUDSMAN_COUNT_SUCCESS,
  B2C_OMBUDSMAN_COUNT_FAILED,
  B2C_MAILING_COUNT,
  B2C_MAILING_COUNT_SUCCESS,
  B2C_MAILING_COUNT_FAILED,
  PARTNER_MAILING_COUNT,
  PARTNER_MAILING_COUNT_SUCCESS,
  PARTNER_MAILING_COUNT_FAILED,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAILED,
  GET_ACTIVE_USER,
  GET_ACTIVE_USER_SUCCESS,
  GET_ACTIVE_USER_FAILED,
  DASHBOARD_GET_EXPERTS_LEADS_SUCCESS,
  DASHBOARD_GET_EXPERTS_LEADS_FAILED,
  DASHBOARD_GET_EXPERT_PARTNER_LEADS_SUCCESS,
  DASHBOARD_GET_EXPERT_PARTNER_LEADS_FAILED,
  PARTNER_LEAD_BUCKET_COUNT,
  PARTNER_LEAD_BUCKET_COUNT_SUCCESS,
  PARTNER_LEAD_BUCKET_COUNT_FAILED,
  DASHBOARD_EXECUTIVE_PRODUCTIVITY,
  DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS,
  DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED,
  INVOICE_PROCESSING_START,
  INVOICE_PROCESSING_SUCCESS,
  INVOICE_PROCESSING_FAILED,
  GET_HCR_ALL_STATUS_DATA,
  GET_HCR_ALL_STATUS_DATA_SUCCESS,
  GET_HCR_ALL_STATUS_DATA_FAILED,
} from "./action";
import { toast } from "helpers/Utils";
import moment from "moment";

const initialState = {
  activeUsers: [],
  states: [],

  allLeadBucket: {},
  todayLeadBucket: {},
  monthlyLeadBucket: [],
  leadExports: [],
  bucketCount: [],

  complaintDashboard: {},
  b2cRegistration: {},
  b2cRegistrationCases: [],
  partnerRegistration: {},

  b2cResolution: {},
  partnerResolution: {},
  b2cSattled: {},
  partnerSattled: {},
  b2cInvoiceRaised: [],
  partnerInvoiceCases: {},

  legalPartner: {},

  b2cOmbudsmanCount: [],
  partnerOmbudsmanCount: [],
  newB2COmbudsmanCount: [],
  nerPartnerOmbudsmanCount: [],
  resendB2CCases: [],
  resendPartnerCases: [],

  b2cMailingCount: [],
  partnerMailingCount: [],
  expertLead: [],
  exprtPartnerLead: [],
  loading: false,
  partnerLeadCount: {},
  executiveData: {},
  hcrData : [],
};

export default (state = initialState, action) => {
  let gmailUserArr = action.data?.gmailUsersArr?.map(res => {
    let dob = res.DOB ? moment.unix(res.DOB).format("DD-MM-YYYY") : "--"
    return { ...res, DOB: dob }
  })
  let nongmailUserArr = action.data?.nonGmailAccessUsers?.map(res => {
    let dob = res.DOB ? moment.unix(res.DOB).format("DD-MM-YYYY") : "--"
    return { ...res, DOB: dob }
  })

  let b2cRegLiObj = getCountB2C(action.data?.caseMonthLiAll)
  let b2cRegHiObj = getCountB2C(action.data?.caseMonthHiAll)
  let b2cRegGiObj = getCountB2C(action.data?.caseMonthGiAll)
  let b2cRegTotalObj = getCountB2C(action.data?.caseMonthAll)

  let partnerResLiObj = getCountB2C(action.data?.caseMonthLiAll)
  let partnerResHiObj = getCountB2C(action.data?.caseMonthHiAll)
  let partnerResGiObj = getCountB2C(action.data?.caseMonthGiAll)
  let partnerResTotalObj = getCountB2C(action.data?.caseMonthAll)

  let b2cResLiObj = getCountB2C(action.data?.caseMonthLiAll)
  let b2cResHiObj = getCountB2C(action.data?.caseMonthHiAll)
  let b2cResGiObj = getCountB2C(action.data?.caseMonthGiAll)
  let b2cResTotalObj = getCountB2C(action.data?.caseMonthAll)

  switch (action.type) {
    case ALL_LEAD_BUCKET:
      return { ...state, loading: true };

    case ALL_LEAD_BUCKET_SUCCESS:
      return { ...state, allLeadBucket: { ...action.data, gmailUsersArr: gmailUserArr, nonGmailAccessUsers: nongmailUserArr }, loading: false };

    case TODAY_LEAD_BUCKET:
      return { ...state, loading: true };

    case TODAY_LEAD_BUCKET_SUCCESS:
      return { ...state, todayLeadBucket: action.data, loading: false };

    case TODAY_LEAD_BUCKET_FAILED:
      return { ...state, message: action.message, loading: false };

    case MONTHLY_LEAD_BUCKET:
      return { ...state, loading: true };

    case MONTHLY_LEAD_BUCKET_SUCCESS:
      return { ...state, monthlyLeadBucket: action.data, loading: false };

    case MONTHLY_LEAD_BUCKET_FAILED:
      return { ...state, message: action.message, loading: false };

    case LEAD_EXPERT_BUCKET:
      return { ...state, loading: true };

    case LEAD_EXPERT_BUCKET_SUCCESS:
      return { ...state, leadExports: action.data, loading: false };

    case LEAD_EXPERT_BUCKET_FAILED:
      return { ...state, message: action.message, loading: false };

    case LEAD_BUCKET_COUNT:
      return { ...state, loading: true };

    case LEAD_BUCKET_COUNT_SUCCESS:
      return { ...state, bucketCount: action.data, loading: false };

    case LEAD_BUCKET_COUNT_FAILED:
      return { ...state, message: action.message, loading: false };

    case COMPLAINT_DASHBOARD:
      return { ...state, loading: true };

    case COMPLAINT_DASHBOARD_SUCCESS:
      return { ...state, complaintDashboard: action.data, loading: false };

    case COMPLAINT_DASHBOARD_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_REGISTRATION:
      return { ...state, loading: true };

    case B2C_REGISTRATION_SUCCESS:
      return { ...state, b2cRegistration: { ...action.data, b2cRegLiObj, b2cRegHiObj, b2cRegGiObj, b2cRegTotalObj }, loading: false };

    case B2C_REGISTRATION_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_REGISTRATION_CASES:
      return { ...state, loading: true };

    case B2C_REGISTRATION_CASES_SUCCESS:
      return { ...state, b2cRegistrationCases: action.data, loading: false };

    case B2C_REGISTRATION_CASES_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_REGISTRATION:
      return { ...state, loading: true };

    case PARTNER_REGISTRATION_SUCCESS:
      return { ...state, partnerRegistration: action.data, loading: false };

    case PARTNER_REGISTRATION_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_RESOLUTION:
      return { ...state, loading: true };

    case B2C_RESOLUTION_SUCCESS:
      return { ...state, b2cResolution: { ...action.data, b2cResGiObj, b2cResHiObj, b2cResLiObj, b2cResTotalObj }, loading: false };

    case B2C_RESOLUTION_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_RESOLUTION:
      return { ...state, loading: true };

    case PARTNER_RESOLUTION_SUCCESS:
      return { ...state, partnerResolution: { ...action.data, partnerResLiObj, partnerResHiObj, partnerResGiObj, partnerResTotalObj, loading: false } };

    case PARTNER_RESOLUTION_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_SATTLED:
      return { ...state, loading: true };

    case B2C_SATTLED_SUCCESS:
      return { ...state, b2cSattled: action.data, loading: false };

    case B2C_SATTLED_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_SATTLED_CASES:
      return { ...state, loading: true };

    case PARTNER_SATTLED_CASES_SUCCESS:
      return { ...state, partnerSattled: action.data, loading: false };

    case PARTNER_SATTLED_CASES_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_INVOICE_RAISED:
      return { ...state, loading: true };

    case B2C_INVOICE_RAISED_SUCCESS:
      return { ...state, b2cInvoiceRaised: action.data, loading: false };

    case B2C_INVOICE_RAISED_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_INVOICE:
      return { ...state, loading: true };

    case PARTNER_INVOICE_SUCCESS:
      return { ...state, partnerInvoiceCases: action.data, loading: false };

    case PARTNER_INVOICE_FAILED:
      return { ...state, message: action.message, loading: false };

    case LEGAL_PARTNER_DASHBOARD:
      return { ...state, loading: true };

    case LEGAL_PARTNER_DASHBOARD_SUCCESS:
      return { ...state, legalPartner: action.data, loading: false };

    case LEGAL_PARTNER_DASHBOARD_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_OMBUDSMAN_COUNT:
      return { ...state, loading: true };

    case B2C_OMBUDSMAN_COUNT_SUCCESS:
      return { ...state, b2cOmbudsmanCount: action.data, loading: false };

    case B2C_OMBUDSMAN_COUNT_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_OMBUDSMAN_COUNT:
      return { ...state, loading: true };

    case PARTNER_OMBUDSMAN_COUNT_SUCCESS:
      return { ...state, partnerOmbudsmanCount: action.data, loading: false };

    case PARTNER_OMBUDSMAN_COUNT_FAILED:
      return { ...state, message: action.message, loading: false };

    case NEW_OMBUDSMAN_COUNT_B2C:
      return { ...state, loading: true };

    case NEW_OMBUDSMAN_COUNT_B2C_SUCCESS:
      return { ...state, newB2COmbudsmanCount: action.data, loading: false };

    case NEW_OMBUDSMAN_COUNT_B2C_FAILED:
      return { ...state, message: action.message, loading: false };

    case NEW_OMBUDSMAN_COUNT_PARTNER:
      return { ...state, loading: true };

    case NEW_OMBUDSMAN_COUNT_PARTNER_SUCCESS:
      return { ...state, nerPartnerOmbudsmanCount: action.data, loading: false };

    case NEW_OMBUDSMAN_COUNT_PARTNER_FAILED:
      return { ...state, message: action.message, loading: false };

    case OMBUDSMAN_RESEND_CASES_B2C:
      return { ...state, loading: true };

    case OMBUDSMAN_RESEND_CASES_B2C_SUCCESS:
      return { ...state, resendB2CCases: action.data, loading: false };

    case OMBUDSMAN_RESEND_CASES_B2C_FAILED:
      return { ...state, message: action.message, loading: false };

    case OMBUDSMAN_RESEND_CASES_PARTNER:
      return { ...state, loading: true };

    case OMBUDSMAN_RESEND_CASES_PARTNER_SUCCESS:
      return { ...state, resendPartnerCases: action.data, loading: false };

    case OMBUDSMAN_RESEND_CASES_PARTNER_FAILED:
      return { ...state, message: action.message, loading: false };

    case B2C_MAILING_COUNT:
      return { ...state, loading: true };

    case B2C_MAILING_COUNT_SUCCESS:
      return { ...state, b2cMailingCount: action.data, loading: false };

    case B2C_MAILING_COUNT_FAILED:
      return { ...state, message: action.message, loading: false };

    case PARTNER_MAILING_COUNT:
      return { ...state };

    case PARTNER_MAILING_COUNT_SUCCESS:
      return { ...state, partnerMailingCount: action.data };

    case PARTNER_MAILING_COUNT_FAILED:
      return { ...state, message: action.message };

    case GET_ACTIVE_USER:
      return { ...state };

    case GET_ACTIVE_USER_SUCCESS:
      return { ...state, activeUsers: action.data };

    case GET_ACTIVE_USER_FAILED:
      return { ...state, message: action.message };

    case GET_STATES:
      return { ...state };

    case GET_STATES_SUCCESS:
      return { ...state, states: action.data };

    case GET_STATES_FAILED:
      return { ...state, message: action.message };

    case DASHBOARD_GET_EXPERTS_LEADS_SUCCESS:
      return { ...state, expertLead: action.data }

    case DASHBOARD_GET_EXPERTS_LEADS_FAILED:
      return { ...state, message: action.message }

    case DASHBOARD_GET_EXPERT_PARTNER_LEADS_SUCCESS:
      return { ...state, exprtPartnerLead: action.data }

    case DASHBOARD_GET_EXPERT_PARTNER_LEADS_FAILED:
      return { ...state, message: action.message }

    case PARTNER_LEAD_BUCKET_COUNT:
      return { ...state };

    case PARTNER_LEAD_BUCKET_COUNT_SUCCESS:
      return { ...state, partnerLeadCount: action.data }

    case PARTNER_LEAD_BUCKET_COUNT_FAILED:
      toast("Failed to Get Data !", "", "error")
      return { ...state }

    case DASHBOARD_EXECUTIVE_PRODUCTIVITY:
      return { ...state, loading: true }

    case DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS:
      return { ...state, executiveData: action.data, loading: false }

    case DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED:
      return { ...state, message: action.message, loading: false }


    case INVOICE_PROCESSING_START:
      return { ...state, loading: true }
    case INVOICE_PROCESSING_SUCCESS:
      return { ...state, invoiceProcessing: action.data, loading: false }

    case INVOICE_PROCESSING_FAILED:
      return { ...state, message: action.message, loading: false }

    case GET_HCR_ALL_STATUS_DATA:
      return { ...state, hcrLoading: true }

    case GET_HCR_ALL_STATUS_DATA_SUCCESS:
      return { ...state, hcrLoading: false, hcrData: action.data }

    case GET_HCR_ALL_STATUS_DATA_FAILED:
      return { ...state, hcrLoading: false };

    default:
      return { ...state };
  }
};
