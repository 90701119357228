import { put, takeEvery } from "redux-saga/effects";
import { servicePath } from "constants/defaultValues";
import { request } from "services/requests.services";
// const { servicePath } = require("constants/defaultValues");
// const { request } = require("services/requests.services");s

function* getComplaint(action) {
    try {
        const {data} = yield request("POST", `${servicePath}/lead/getLeadComplaints`, action.state)
        yield put({type:"SEARCH_COMPLAINT_BY_PHONE_SUCCESS", data: data})
    } catch (error) {
        yield put({type:"SEARCH_COMPLAINT_BY_PHONE_FAILED"})
    }
}

export default function* searchCom() {
    yield takeEvery("SEARCH_COMPLAINT_BY_PHONE", getComplaint)
}