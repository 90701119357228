export const USERS_GET_LIST = "USERS_GET_LIST"
export const USERS_GET_LIST_SUCCESS = "USERS_GET_LIST_SUCCESS"
export const USERS_GET_LIST_FAILED = "USERS_GET_LIST_FAILED"

export const USER_GET_STATUS_BUCKET = "USER_GET_STATUS_BUCKET"
export const USER_GET_STATUS_BUCKET_SUCCESS = "USER_GET_STATUS_BUCKET_SUCCESS"
export const USER_GET_STATUS_BUCKET_FAILED = "USER_GET_STATUS_BUCKET_FAILED"

export const USER_GET_ASSIGN_USER = "USER_GET_ASSIGN_USER"
export const USER_GET_ASSIGN_USER_SUCCESS = "USER_GET_ASSIGN_USER_SUCCESS"
export const USER_GET_ASSIGN_USER_FAILED = "USER_GET_ASSIGN_USER_FAILED"

export const GET_ASSIGN_LEGAL_EXECUTIVE = "GET_ASSIGN_LEGAL_EXECUTIVE"
export const GET_ASSIGN_LEGAL_EXECUTIVE_SUCCESS = "GET_ASSIGN_LEGAL_EXECUTIVE_SUCCESS"
export const GET_ASSIGN_LEGAL_EXECUTIVE_FAILED = "GET_ASSIGN_LEGAL_EXECUTIVE_FAILED"

export const USER_GET_LEGAL_SUBEXECUTIVE = "USER_GET_LEGAL_SUBEXECUTIVE"
export const USER_GET_LEGAL_SUBEXECUTIVE_SUCCESS = "USER_GET_LEGAL_SUBEXECUTIVE_SUCCESS"
export const USER_GET_LEGAL_SUBEXECUTIVE_FAILED = "USER_GET_LEGAL_SUBEXECUTIVE_FAILED"

export const USER_UPDATE_USER_SUCCESS = "USER_UPDATE_USER_SUCCESS"
export const USER_UPDATE_USER_FAILED = "USER_UPDATE_USER_FAILED"

export const USER_ADD_USER_SUCCESS = "USER_ADD_USER_SUCCESS"
export const USER_ADD_USER_FAILED = "USER_ADD_USER_FAILED"

export const USER_UPDATE_PASSWORD = "USER_UPDATE_PASSWORD"
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS"
export const USER_UPDATE_PASSWORD_FAILED = "USER_UPDATE_PASSWORD_FAILED"