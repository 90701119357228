export const GET_COMPLAINT_TYPE_LIST = 'GET_COMPLAINT_TYPE_LIST';
export const GET_COMPLAINT_TYPE_LIST_SUCCESS = 'GET_COMPLAINT_TYPE_LIST_SUCCESS';
export const GET_COMPLAINT_TYPE_LIST_FAILURE = 'GET_COMPLAINT_TYPE_LIST_FAILURE';

export const ADD_COMPLAINT_TYPE = 'ADD_COMPLAINT_TYPE';
export const ADD_COMPLAINT_TYPE_SUCCESS = 'ADD_COMPLAINT_TYPE_SUCCESS';
export const ADD_COMPLAINT_TYPE_FAILURE = 'ADD_COMPLAINT_TYPE_FAILURE';

export const UPDATE_COMPLAINT_TYPE = 'UPDATE_COMPLAINT_TYPE';
export const UPDATE_COMPLAINT_TYPE_SUCCESS = 'UPDATE_COMPLAINT_TYPE_SUCCESS';
export const UPDATE_COMPLAINT_TYPE_FAILURE = 'UPDATE_COMPLAINT_TYPE_FAILURE';

