import { put, takeEvery } from "redux-saga/effects";
import {
  ADD_HOSPITALS_LIST,
  ADD_HOSPITALS_LIST_FAILURE,
  ADD_HOSPITALS_LIST_SUCCESS,
  GET_HOSPITALS_LIST,
  GET_HOSPITALS_LIST_FAILURE,
  GET_HOSPITALS_LIST_SUCCESS,
  UPDATE_HOSPITALS_LIST,
  UPDATE_HOSPITALS_LIST_FAILURE,
  UPDATE_HOSPITALS_LIST_SUCCESS,
} from "./Action";
import { partnerRequest } from "services/requests.services";
import { partnerServicePath } from "constants/defaultValues";

function* getHospitalsList() {
  try {
    const res = yield partnerRequest(
      "GET",
      `${partnerServicePath}/hospital/getAllHospitals`
    );
    
    yield put({ type: GET_HOSPITALS_LIST_SUCCESS, data: res.data.data });
  } catch (err) {
    yield put({ type: GET_HOSPITALS_LIST_FAILURE });
    // console.log(err);
  }
}

function* addHospital(action) {
  try {
    yield partnerRequest(
      "POST",
      `${partnerServicePath}/hospital/addNewHospital`,
      action.data
    );
    yield put({ type: GET_HOSPITALS_LIST });
    yield put({ type: ADD_HOSPITALS_LIST_SUCCESS });
  } catch (err) {
    yield put({ type: ADD_HOSPITALS_LIST_FAILURE });
    // console.log(err);
  }
}

function* updateHospital(action) {
  try {
    yield partnerRequest(
      "POST",
      `${partnerServicePath}/hospital/updateSingleHospital`,
      action.data
    );
    yield put({ type: GET_HOSPITALS_LIST });
    yield put({ type: UPDATE_HOSPITALS_LIST_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_HOSPITALS_LIST_FAILURE });
    // console.log(err);
  }
}

export default function* hospitalsSaga() {
  yield takeEvery(GET_HOSPITALS_LIST, getHospitalsList);
  yield takeEvery(ADD_HOSPITALS_LIST, addHospital);
  yield takeEvery(UPDATE_HOSPITALS_LIST, updateHospital);
}
