export const DOCUMENT_UPLOAD_VERIFY_USER = "DOCUMENT_UPLOAD_VERIFY_USER";
export const DOCUMENT_UPLOAD_VERIFY_USER_FAILED = "DOCUMENT_UPLOAD_VERIFY_USER_FAILED";
export const DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS = "DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS";

export const DOCUMENT_HCR_UPLOAD_VERIFY_USER = "DOCUMENT_HCR_UPLOAD_VERIFY_USER";
export const DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED = "DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED";
export const DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS = "DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS";

export const DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE = "DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE";
export const DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED = "DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED";
export const DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS = "DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS";

export const DOCUMENT_UPLOAD_VERIFY_OTP = "DOCUMENT_UPLOAD_VERIFY_OTP";
export const DOCUMENT_UPLOAD_VERIFY_OTP_FAILED = "DOCUMENT_UPLOAD_VERIFY_OTP_FAILED";
export const DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS = "DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS";

export const DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE = "DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE";
export const DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE_FAILED = "DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE_FAILED";
export const DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE_SUCCESS = "DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS";

export const DOCUMENT_UPLOAD_GET_DOCUMENT_LIST = "DOCUMENT_UPLOAD_GET_DOCUMENT_LIST";
export const DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED = "DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED";
export const DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS = "DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS";

export const DOCUMENT_UPLOAD_UPLOAD_FILE = "DOCUMENT_UPLOAD_UPLOAD_FILE";
export const DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER = "DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER";
export const DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED = "DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED";
export const DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS = "DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS";

export const DOCUMENT_UPLOAD_DELETE_HCR_SINGLE_DOC = "DOCUMENT_UPLOAD_DELETE_HCR_SINGLE_DOC";

export const DOCUMENT_UPLOAD_DELETE_SINGLE_DOC = "DOCUMENT_UPLOAD_DELETE_SINGLE_DOC";
export const DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED = "DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED";
export const DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS = "DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS";


export const DOCUMENT_UPLOAD_GET_SINGLE_LEAD = "DOCUMENT_UPLOAD_GET_SINGLE_LEAD";
export const DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED = "DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED";
export const DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS = "DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS";

export const DOCUMENT_UPLOAD_SUBMIT_ACTION = "DOCUMENT_UPLOAD_SUBMIT_ACTION"
export const DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED = "DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED"
export const DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS = "DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS"