import { put, takeEvery } from "redux-saga/effects";
import {
  ADD_DROPDOWN_LIST,
  ADD_DROPDOWN_LIST_FAILURE,
  ADD_DROPDOWN_LIST_SUCCESS,
  GET_DROPDOWN_LIST,
  GET_DROPDOWN_LIST_FAILURE,
  GET_DROPDOWN_LIST_SUCCESS,
  UPDATE_DROPDOWN_LIST,
  UPDATE_DROPDOWN_LIST_FAILURE,
  UPDATE_DROPDOWN_LIST_SUCCESS,
} from "./Action";
import { request } from "services/requests.services";
import { servicePath } from "constants/defaultValues";

function* getDropdownList() {
  try {
    let res = yield request("GET", `${servicePath}/dropdown`);
    yield put({ type: GET_DROPDOWN_LIST_SUCCESS, data: res.data });
  } catch (err) {
    // console.log(err);
    yield put({ type: GET_DROPDOWN_LIST_FAILURE });
  }
}

function* addDropdownList(action) {
  try {
    yield request("POST", `${servicePath}/dropdown/add`, action.data);
    yield put({ type: GET_DROPDOWN_LIST });
    yield put({ type: ADD_DROPDOWN_LIST_SUCCESS });
  } catch (err) {
    // console.log(err);
    yield put({ type: ADD_DROPDOWN_LIST_FAILURE });
  }
}

function* updateDropdownList(action) {
  try {
    yield request(
      "POST",
      `${servicePath}/dropdown/edit`,
      action.data
    );
    yield put({ type: GET_DROPDOWN_LIST });
    yield put({ type: UPDATE_DROPDOWN_LIST_SUCCESS });
  } catch (err) {
    // console.log(err);
    yield put({ type: UPDATE_DROPDOWN_LIST_FAILURE });
  }
}

export default function* dropdownSaga() {
  yield takeEvery(GET_DROPDOWN_LIST, getDropdownList);
  yield takeEvery(ADD_DROPDOWN_LIST, addDropdownList);
  yield takeEvery(UPDATE_DROPDOWN_LIST, updateDropdownList);
}
