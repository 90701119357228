export const GET_CUSTOMER_QUERT_DATA = "GET_CUSTOMER_QUERT_DATA"
export const GET_CUSTOMER_QUERT_DATA_SUCCESS = "GET_CUSTOMER_QUERT_DATA_SUCCESS"
export const GET_CUSTOMER_QUERT_DATA_FAILED = "GET_CUSTOMER_QUERT_DATA_FAILED"

export const GET_OPEN_QUERT_DATA = "GET_OPEN_QUERT_DATA"
export const GET_OPEN_QUERT_DATA_SUCCESS = "GET_OPEN_QUERT_DATA_SUCCESS"
export const GET_OPEN_QUERT_DATA_FAILED = "GET_OPEN_QUERT_DATA_FAILED"


export const GET_COMPLAINT_QURIES = "GET_COMPLAINT_QURIES"
export const GET_COMPLAINT_QURIES_SUCCESS = "GET_COMPLAINT_QURIES_SUCCESS"
export const GET_COMPLAINT_QURIES_FAILED = "GET_COMPLAINT_QURIES_FAILED"


export const UPDATE_COMPLAINT_QURIES = "UPDATE_COMPLAINT_QURIES"
export const UPDATE_COMPLAINT_QURIES_SUCCESS = "UPDATE_COMPLAINT_QURIES_SUCCESS"
export const UPDATE_COMPLAINT_QURIES_FAILED = "UPDATE_COMPLAINT_QURIES_FAILED"

export const CLOSE_COMPLAINT_QURIES = "CLOSE_COMPLAINT_QURIES"
export const CLOSE_COMPLAINT_QURIES_SUCCESS = "CLOSE_COMPLAINT_QURIES_SUCCESS"
export const CLOSE_COMPLAINT_QURIES_FAILED = "CLOSE_COMPLAINT_QURIES_FAILED"