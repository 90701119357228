import { toast } from "helpers/Utils";
import { SEARCH_COMPLAINT_BY_PHONE, SEARCH_COMPLAINT_BY_PHONE_FAILED, SEARCH_COMPLAINT_BY_PHONE_SUCCESS } from "./Action";


const initialState = {
    dataArr:[],
    loading:false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_COMPLAINT_BY_PHONE:
            return {...state, loading:true}

        case SEARCH_COMPLAINT_BY_PHONE_SUCCESS:
            if (action.data?.leadData?.length) {
                toast("Success !", "", "success")
            } else if (!action.data?.leadData?.length) {
                toast("No Record Found !", "", "error")
            }
            return {...state, dataArr:action.data, loading:false}

        case SEARCH_COMPLAINT_BY_PHONE_FAILED:
            toast("Something wrong !", "Please try again !", "error")
            return {...state, loading:false, dataArr:[]}
    
        default:
            return {...state}
    }
}