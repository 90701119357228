import axios from 'axios';
// import { setCurrentUser } from 'helpers/Utils';
// import store from 'redux/store';

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            // setCurrentUser();
            // window.location.href = "/user/login"
            // store.dispatch({type:"AUTHENTICATE_USER",payload:{isLogin:false}})
        }
        return Promise.reject(error);
    }
)

export default axios