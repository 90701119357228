import { servicePath } from "constants/defaultValues";
import { log } from "helpers/Utils";
import {
  // call,
  put,
  takeEvery,
  // takeLatest,
  // all,
  // fork,
} from "redux-saga/effects";
import { request } from "services/requests.services";


function* getClaimFilings(action) {
  try {
    const {
      pageIndex = 0,
      pageSize = 10,
      keyword = "",
      selectedSortOrder,
      startDate,
      endDate
    } = action.state;

    let url = `${servicePath}/filtration/claimfiling/get?pageIndex=${pageIndex}&pageSize=${pageSize}`
    if (keyword) {
      url += `&keyword=${keyword}`
    }
    if (selectedSortOrder) {
      url += `&selectedSortOrder=${selectedSortOrder}`
    }
    if (startDate && endDate) {
      url += `&startDate=${startDate}&endDate=${endDate}`
    }

    const data = yield request(
      "GET",
      url
    )
    yield put({ type: "CLAIM_FILING_GET_SUCCESS", data: data.data });
  }
  catch (error) {
    yield put({
      type: "CLAIM_FILING_GET_FAILED",
      message: "Failed to fetch data !"
    })
  }
}

function* getCFReport(action) {
  try {
    const {
      startDate,
      endDate
    } = action.state;

    const data = yield request(
      "GET",
      `${servicePath}/filtration/claimfiling/downloadReport${startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ""}`
    )
    yield put({ type: "CLAIM_FILING_DOWNLOAD_REPORT_SUCCESS", data: data.data });
  }
  catch (error) {
    log(error);
    yield put({
      type: "CLAIM_FILING_DOWNLOAD_REPORT_FAILED",
      message: "Failed to fetch data !"
    })
  }
}

export default function* claimFilingSaga() {
  yield takeEvery("CLAIM_FILING_GET", getClaimFilings);
  yield takeEvery("CLAIM_FILING_DOWNLOAD_REPORT", getCFReport);
}
