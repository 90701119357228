export const CUSTOMER_GET_USER_LIST = "CUSTOMER_GET_USER_LIST";
export const CUSTOMER_GET_USER_LIST_SUCCESS = "CUSTOMER_GET_USER_LIST_SUCCESS";
export const CUSTOMER_GET_USER_LIST_FAILED = "CUSTOMER_GET_USER_LIST_FAILED";

export const CUSTOMER_FILTER_DATA = "CUSTOMER_FILTER_DATA"
export const CUSTOMER_FILTER_DATA_SUCCESS = "CUSTOMER_FILTER_DATA_SUCCESS"
export const CUSTOMER_FILTER_DATA_FAILED = "CUSTOMER_FILTER_DATA_FAILED"

export const CUSTOMER_VIEW_PASSWORD = "CUSTOMER_VIEW_PASSWORD"
export const CUSTOMER_VIEW_PASSWORD_SUCCESS = "CUSTOMER_VIEW_PASSWORD_SUCCESS"
export const CUSTOMER_VIEW_PASSWORD_FAILED = "CUSTOMER_VIEW_PASSWORD_FAILED"

export const CUSTOMER_UPDATE_SERVICE_RATE = "CUSTOMER_UPDATE_SERVICE_RATE"
export const CUSTOMER_UPDATE_SERVICE_RATE_SUCCESS = "CUSTOMER_UPDATE_SERVICE_RATE_SUCCESS"
export const CUSTOMER_UPDATE_SERVICE_RATE_FAILED = "CUSTOMER_UPDATE_SERVICE_RATE_FAILED"

export const CUSTOMER_GMAIL_READ = "CUSTOMER_GMAIL_READ"
export const CUSTOMER_GMAIL_READ_SUCCESS = "CUSTOMER_GMAIL_READ_SUCCESS"
export const CUSTOMER_GMAIL_READ_FAILED = "CUSTOMER_GMAIL_READ_FAILED"

export const CUSTOMER_ADMIN_ISSUED_DOC = "CUSTOMER_ADMIN_ISSUED_DOC"
export const CUSTOMER_ADMIN_ISSUED_DOC_SUCCESS = "CUSTOMER_ADMIN_ISSUED_DOC_SUCCESS"
export const CUSTOMER_ADMIN_ISSUED_DOC_FAILED = "CUSTOMER_ADMIN_ISSUED_DOC_FAILED"


export const CUSTOMER_ADMIN_SELF_DOC = "CUSTOMER_ADMIN_SELF_DOC"
export const CUSTOMER_ADMIN_SELF_DOC_SUCCESS = "CUSTOMER_ADMIN_SELF_DOC_SUCCESS"
export const CUSTOMER_ADMIN_SELF_DOC_FAILED = "CUSTOMER_ADMIN_SELF_DOC_FAILED"


export const CUSTOMER_USER_DOC_LIST = "CUSTOMER_USER_DOC_LIST"
export const CUSTOMER_USER_DOC_LIST_SUCCESS = "CUSTOMER_USER_DOC_LIST_SUCCESS"
export const CUSTOMER_USER_DOC_LIST_FAILED = "CUSTOMER_USER_DOC_LIST_FAILED"

export const CUSTOMER_ADD_COMMENT = "CUSTOMER_ADD_COMMENT"
export const CUSTOMER_ADD_COMMENT_SUCCESS = "CUSTOMER_ADD_COMMENT_SUCCESS"
export const CUSTOMER_ADD_COMMENT_FAILED = "CUSTOMER_ADD_COMMENT_FAILED"

export const CUSTOMER_DOWNLOAD_REPORT = "CUSTOMER_DOWNLOAD_REPORT"
export const CUSTOMER_DOWNLOAD_REPORT_SUCCESS = "CUSTOMER_DOWNLOAD_REPORT_SUCCESS"
export const CUSTOMER_DOWNLOAD_REPORT_FAILED = "CUSTOMER_DOWNLOAD_REPORT_FAILED"

export const CUSTOMER_UPDATE_DATA = "CUSTOMER_UPDATE_DATA"
export const CUSTOMER_UPDATE_DATA_SUCCESS = "CUSTOMER_UPDATE_DATA_SUCCESS"
export const CUSTOMER_UPDATE_DATA_FAILED = "CUSTOMER_UPDATE_DATA_FAILED"

export const CUSTOMER_GET_PASSWORD_RATE = "CUSTOMER_GET_PASSWORD_RATE"
export const CUSTOMER_GET_PASSWORD_RATE_SUCCESS = "CUSTOMER_GET_PASSWORD_RATE_SUCCESS"
export const CUSTOMER_GET_PASSWORD_RATE_FAILED = "CUSTOMER_GET_PASSWORD_RATE_FAILED"

export const CUSTOMER_ADD_COMM_COMMENT = "CUSTOMER_ADD_COMM_COMMENT"
export const CUSTOMER_ADD_COMM_COMMENT_SUCCESS = "CUSTOMER_ADD_COMM_COMMENT_SUCCESS"
export const CUSTOMER_ADD_COMM_COMMENT_FAILED = "CUSTOMER_ADD_COMM_COMMENT_FAILED"

export const CUSTOMER_BOT_FOLLOW_UP = "CUSTOMER_BOT_FOLLOW_UP"
export const CUSTOMER_BOT_FOLLOW_UP_SUCCESS = "CUSTOMER_BOT_FOLLOW_UP_SUCCESS"
export const CUSTOMER_BOT_FOLLOW_UP_FAILED = "CUSTOMER_BOT_FOLLOW_UP_FAILED"


export const CUSTOMER_UNBLOCK_DATA = "CUSTOMER_UNBLOCK_DATA"
export const CUSTOMER_UNBLOCK_DATA_SUCESS = "CUSTOMER_UNBLOCK_DATA_SUCESS"
export const CUSTOMER_UNBLOCK_DATA_FAILED = "CUSTOMER_UNBLOCK_DATA_FAILED"