export const GET_DROPDOWN_LIST = "GET_DROPDOWN_LIST";
export const GET_DROPDOWN_LIST_SUCCESS = "GET_DROPDOWN_LIST_SUCCESS";
export const GET_DROPDOWN_LIST_FAILURE = "GET_DROPDOWN_LIST_FAILURE";

export const ADD_DROPDOWN_LIST = "ADD_DROPDOWN_LIST";
export const ADD_DROPDOWN_LIST_SUCCESS = "ADD_DROPDOWN_LIST_SUCCESS";
export const ADD_DROPDOWN_LIST_FAILURE = "ADD_DROPDOWN_LIST_FAILURE";

export const UPDATE_DROPDOWN_LIST = "UPDATE_DROPDOWN_LIST";
export const UPDATE_DROPDOWN_LIST_SUCCESS = "UPDATE_DROPDOWN_LIST_SUCCESS";
export const UPDATE_DROPDOWN_LIST_FAILURE = "UPDATE_DROPDOWN_LIST_FAILURE";
