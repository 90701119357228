export const REPORT_GET_ACTIVE_STATUS = "REPORT_GET_ACTIVE_STATUS"
export const REPORT_GET_ACTIVE_STATUS_SUCCESS = "REPORT_GET_ACTIVE_STATUS_SUCCESS"
export const REPORT_GET_ACTIVE_STATUS_FAILED = "REPORT_GET_ACTIVE_STATUS_FAILED"

export  const REPORT_GET_USER_AGENT_DATA = "REPORT_GET_USER_AGENT_DATA"
export  const REPORT_GET_USER_AGENT_DATA_SUCCESS = "REPORT_GET_USER_AGENT_DATA_SUCCESS"
export  const REPORT_GET_USER_AGENT_DATA_FAILED = "REPORT_GET_USER_AGENT_DATA_FAILED"

export const REPORT_GET_ALL_USER_LIST = "REPORT_GET_ALL_USER_LIST"
export const REPORT_GET_ALL_USER_LIST_SUCCESS = "REPORT_GET_ALL_USER_LIST_SUCCESS"
export const REPORT_GET_ALL_USER_LIST_FAILED = "REPORT_GET_ALL_USER_LIST_FAILED"


export const REPORT_GET_INSURANCE_TYPE = "REPORT_GET_INSURANCE_TYPE"
export const REPORT_GET_INSURANCE_TYPE_SUCCESS = "REPORT_GET_INSURANCE_TYPE_SUCCESS"
export const REPORT_GET_INSURANCE_TYPE_FAILED = "REPORT_GET_INSURANCE_TYPE_FAILED"



export const REPORT_GET_INSURANCE_COMPANIES = "REPORT_GET_INSURANCE_COMPANIES"
export const REPORT_GET_INSURANCE_COMPANIES_SUCCESS = "REPORT_GET_INSURANCE_COMPANIES_SUCCESS"
export const REPORT_GET_INSURANCE_COMPANIES_FAILED = "REPORT_GET_INSURANCE_COMPANIES_FAILED"


export const REPORT_GET_COMPLAINT_TYPE = "REPORT_GET_COMPLAINT_TYPE"
export const REPORT_GET_COMPLAINT_TYPE_SUCCESS = "REPORT_GET_COMPLAINT_TYPE_SUCCESS"
export const REPORT_GET_COMPLAINT_TYPE_FAILED = "REPORT_GET_COMPLAINT_TYPE_FAILED"

export const REPORT_UPLOAD_DOC = "REPORT_UPLOAD_DOC"
export const REPORT_UPLOAD_DOC_SUCCESS = "REPORT_UPLOAD_DOC_SUCCESS"
export const REPORT_UPLOAD_DOC_FAILED = "REPORT_UPLOAD_DOC_FAILED"

export const REPORT_UPLOAD_TO_SERVER = "REPORT_UPLOAD_TO_SERVER"
export const REPORT_UPLOAD_TO_SERVER_SUCCESS = "REPORT_UPLOAD_TO_SERVER_SUCCESS"
export const REPORT_UPLOAD_TO_SERVER_FAILED = "REPORT_UPLOAD_TO_SERVER_FAILED"

export const REPORT_RESOLVED_CASES = "REPORT_RESOLVED_CASES"
export const REPORT_RESOLVED_CASES_SUCCESS = "REPORT_RESOLVED_CASES_SUCCESS"
export const REPORT_RESOLVED_CASES_FAILED = "REPORT_RESOLVED_CASES_FAILED"

export const REPORT_USER_REPORT_SECTION = "REPORT_USER_REPORT_SECTION"
export const REPORT_USER_REPORT_SECTION_SUCCESS = "REPORT_USER_REPORT_SECTION_SUCCESS"
export const REPORT_USER_REPORT_SECTION_FAILED = "REPORT_USER_REPORT_SECTION_FAILED"

export const REPORT_CASE_REPORT_DATA = "REPORT_CASE_REPORT_DATA"
export const REPORT_CASE_REPORT_DATA_SUCCESS = "REPORT_CASE_REPORT_DATA_SUCCESS"
export const REPORT_CASE_REPORT_DATA_FAILED = "REPORT_CASE_REPORT_DATA_FAILED"


export const REPORT_CASE_REPORT_STATUS_DATA = "REPORT_CASE_REPORT_STATUS_DATA"
export const REPORT_CASE_REPORT_STATUS_DATA_SUCCESS = "REPORT_CASE_REPORT_STATUS_DATA_SUCCESS"
export const REPORT_CASE_REPORT_STATUS_DATA_FAILED = "REPORT_CASE_REPORT_STATUS_DATA_FAILED"

export const REPORT_CASE_REPORT_AGENT_DATA = "REPORT_CASE_REPORT_AGENT_DATA"
export const REPORT_CASE_REPORT_AGENT_DATA_SUCCESS = "REPORT_CASE_REPORT_AGENT_DATA_SUCCESS"
export const REPORT_CASE_REPORT_AGENT_DATA_FAILED = "REPORT_CASE_REPORT_AGENT_DATA_FAILED"

export const REPORT_CASE_REPORT_COUNT_DATA = "REPORT_CASE_REPORT_COUNT_DATA"
export const REPORT_CASE_REPORT_COUNT_DATA_SUCCESS = "REPORT_CASE_REPORT_COUNT_DATA_SUCCESS"
export const REPORT_CASE_REPORT_COUNT_DATA_FAILED = "REPORT_CASE_REPORT_COUNT_DATA_FAILED"


export const LEAD_REPORT_COUNT_DATA = "LEAD_REPORT_COUNT_DATA"
export const LEAD_REPORT_COUNT_DATA_SUCCESS = "LEAD_REPORT_COUNT_DATA_SUCCESS"
export const LEAD_REPORT_COUNT_DATA_FAILED = "LEAD_REPORT_COUNT_DATA_FAILED"


export const TODAY_LEAD_COUNT_DATA = "TODAY_LEAD_COUNT_DATA"
export const TODAY_LEAD_COUNT_DATA_SUCCESS = "TODAY_LEAD_COUNT_DATA_SUCCESS"
export const TODAY_LEAD_COUNT_DATA_FAILED = "TODAY_LEAD_COUNT_DATA_FAILED"

export const LEAD_REPORT_DATA = "LEAD_REPORT_DATA"
export const LEAD_REPORT_DATA_SUCCESS = "LEAD_REPORT_DATA_SUCCESS"
export const LEAD_REPORT_DATA_FAILED = "LEAD_REPORT_DATA_FAILED"

export const PAYMENT_REPORT_DATA = "PAYMENT_REPORT_DATA"
export const PAYMENT_REPORT_DATA_SUCCESS = "PAYMENT_REPORT_DATA_SUCCESS"
export const PAYMENT_REPORT_DATA_FAILED = "PAYMENT_REPORT_DATA_FAILED"


export const PAYMENT_REPORT_STATUS_DATA = "PAYMENT_REPORT_STATUS_DATA"
export const PAYMENT_REPORT_STATUS_DATA_SUCCESS = "PAYMENT_REPORT_STATUS_DATA_SUCCESS"
export const PAYMENT_REPORT_STATUS_DATA_FAILED = "PAYMENT_REPORT_STATUS_DATA_FAILED"


export const MONTHLY_REPORT_DATA = "MONTHLY_REPORT_DATA"
export const MONTHLY_REPORT_DATA_SUCCESS = "MONTHLY_REPORT_DATA_SUCCESS"
export const MONTHLY_REPORT_DATA_FAILED = "MONTHLY_REPORT_DATA_FAILED"

export const COMPLAINT_MONTHLY_REPORT = "COMPLAINT_MONTHLY_REPORT"
export const COMPLAINT_MONTHLY_REPORT_SUCCESS = "COMPLAINT_MONTHLY_REPORT_SUCCESS"
export const COMPLAINT_MONTHLY_REPORT_FAILED = "COMPLAINT_MONTHLY_REPORT_FAILED"


export const AGENT_CASES_MONTHLY_REPORT = "AGENT_CASES_MONTHLY_REPORT"
export const AGENT_CASES_MONTHLY_REPORT_SUCCESS = "AGENT_CASES_MONTHLY_REPORT_SUCCESS"
export const AGENT_CASES_MONTHLY_REPORT_FAILED = "AGENT_CASES_MONTHLY_REPORT_FAILED"


export const AGENT_CASES_CONTRACT_MONTHLY_REPORT = "AGENT_CASES_CONTRACT_MONTHLY_REPORT"
export const AGENT_CASES_CONTRACT_MONTHLY_REPORT_SUCCESS = "AGENT_CASES_CONTRACT_MONTHLY_REPORT_SUCCESS"
export const AGENT_CASES_CONTRACT_MONTHLY_REPORT_FAILED = "AGENT_CASES_CONTRACT_MONTHLY_REPORT_FAILED"

export const COMPLAINT_REPORT_DATA = "COMPLAINT_REPORT_DATA"
export const COMPLAINT_REPORT_DATA_SUCCESS = "COMPLAINT_REPORT_DATA_SUCCESS"
export const COMPLAINT_REPORT_DATA_FAILED = "COMPLAINT_REPORT_DATA_FAILED"


export const OMBUDSMAN_EXECUTIVE_REPORT = "OMBUDSMAN_EXECUTIVE_REPORT"
export const OMBUDSMAN_EXECUTIVE_REPORT_SUCCESS = "OMBUDSMAN_EXECUTIVE_REPORT_SUCCESS"
export const OMBUDSMAN_EXECUTIVE_REPORT_FAILED = "OMBUDSMAN_EXECUTIVE_REPORT_FAILED"


export const REPORT_RESOLVED_PAYMENT_CASES = "REPORT_RESOLVED_PAYMENT_CASES"
export const REPORT_RESOLVED_PAYMENT_CASES_SUCCESS = "REPORT_RESOLVED_PAYMENT_CASES_SUCCESS"
export const REPORT_RESOLVED_PAYMENT_CASES_FAILED = "REPORT_RESOLVED_PAYMENT_CASES_FAILED"

export const REGISTERED_LEAD_REPORT = "REGISTERED_LEAD_REPORT"
export const REGISTERED_LEAD_REPORT_SUCCESS = "REGISTERED_LEAD_REPORT_SUCCESS"
export const REGISTERED_LEAD_REPORT_FAILED = "REGISTERED_LEAD_REPORT_FAILED"

export const MONTHLY_RESOLVED_B2C = "MONTHLY_RESOLVED_B2C"
export const MONTHLY_RESOLVED_B2C_SUCCESS = "MONTHLY_RESOLVED_B2C_SUCCESS"
export const MONTHLY_RESOLVED_B2C_FAILED = "MONTHLY_RESOLVED_B2C_FAILED"

export const OMB_REJECT_CASES = "OMB_REJECT_CASES"
export const OMB_REJECT_CASES_SUCCESS = "OMB_REJECT_CASES_SUCCESS"
export const OMB_REJECT_CASES_FAILED = "OMB_REJECT_CASES_FAILED"

export const TAT_REPORT_DATA = "TAT_REPORT_DATA"
export const TAT_REPORT_DATA_SUCCESS = "TAT_REPORT_DATA_SUCCESS"
export const TAT_REPORT_DATA_FAILED = "TAT_REPORT_DATA_FAILED"


export const BOT_REPORT_DATA = "BOT_REPORT_DATA"
export const BOT_REPORT_DATA_SUCCESS = "BOT_REPORT_DATA_SUCCESS"
export const BOT_REPORT_DATA_FAILED = "BOT_REPORT_DATA_FAILED"

export const REPORT_USER_REPORT_DATA = "REPORT_USER_REPORT_DATA"
export const REPORT_USER_REPORT_DATA_SUCCESS = "REPORT_USER_REPORT_DATA_SUCCESS"
export const REPORT_USER_REPORT_DATA_FAILED = "REPORT_USER_REPORT_DATA_FAILED"

export const REPORT_DOWNLOAD_TAX_INVOICE = "REPORT_DOWNLOAD_TAX_INVOICE"
export const REPORT_DOWNLOAD_TAX_INVOICE_SUCCESS = "REPORT_DOWNLOAD_TAX_INVOICE_SUCCESS"
export const REPORT_DOWNLOAD_TAX_INVOICE_FAILED = "REPORT_DOWNLOAD_TAX_INVOICE_FAILED"


export const LEAD_STATUS_REPORT = "LEAD_STATUS_REPORT"
export const LEAD_STATUS_REPORT_SUCCESS = "LEAD_STATUS_REPORT_SUCCESS"
export const LEAD_STATUS_REPORT_FAILED = "LEAD_STATUS_REPORT_FAILED"